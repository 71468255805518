import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import { CriticalError, StripeError } from '@vates/xw-api-errors'
import { last } from 'lodash'
import { injectState, provideState } from 'reaclette'
import { injectStripe } from 'react-stripe-elements'

import { getApi } from '../../api'
import Confirmation from './confirmation'
import BillingInformation from './billing-information'
import PlanDetails from './plan-details'
import Payment from './payment'
import Stepper from '../components/stepper'
import Summary from './summary'
import {
  addOrReplaceStripeCard,
  STRIPE,
  cancelPayment,
  isValidBillingInfo,
  isValidOrder,
  isValidPayment,
  paymentAction
} from '../purchase-utils'
import { makeStepperData } from '../../utils'

const PLAN_DETAIL = 1
const BILLING_INFO = 2
const PAYMENT = 3
const CONFIRMATION = 4

const STEPS_PROPS_TEMPLATE = {
  [PLAN_DETAIL]: {
    state: {
      account: '',
      country: '',
      currency: '',
      initialPurchase: '',
      order: '',
      preSelectedProduct: '',
      role: '',
      xwToken: ''
    },
    effects: {
      addToOrder: '',
      resetOrder: '',
      updateOrder: ''
    }
  },
  [BILLING_INFO]: {
    state: {
      account: '',
      baseUrl: '',
      country: '',
      role: '',
      xwToken: ''
    },
    effects: {
      updateBillingInfo: ''
    }
  },
  [PAYMENT]: {
    state: {
      account: '',
      order: '',
      payment: ''
    },
    effects: {
      updatePayment: ''
    }
  },
  [CONFIRMATION]: {
    state: {
      account: '',
      payment: ''
    },
    effects: {}
  }
}

const STEPS = [
  {
    stepComponent: PlanDetails,
    stepIndex: PLAN_DETAIL,
    stepName: 'Plan details',
    onNextStep: appData => isValidOrder(appData.order)
  },
  {
    stepComponent: BillingInformation,
    stepIndex: BILLING_INFO,
    stepName: 'Billing information',
    onNextStep: async (appData, effects) => {
      if (isValidBillingInfo(appData.account)) {
        try {
          await getApi().updateAccountInfo(
            appData.xwToken,
            appData.account.billingInfo
          )
          return true
        } catch (error) {
          effects.handleError(error)
          return false
        }
      }
      return false
    }
  },
  {
    stepComponent: Payment,
    stepIndex: PAYMENT,
    stepName: 'Payment',
    onNextStep: async (appData, effects) => {
      try {
        if (
          appData.payment.paymentMethod === STRIPE &&
          appData.payment.usePreviousCard !== true
        ) {
          await effects.createStripeToken()
          await addOrReplaceStripeCard(
            appData.xwToken,
            appData.account,
            appData.payment.cardToken
          )
          await effects.refreshAccountInfo()
        }
        return isValidPayment(appData.account, appData.payment)
      } catch (error) {
        effects.handleError(error)
        return false
      }
    }
  },
  {
    stepComponent: Confirmation,
    stepIndex: CONFIRMATION,
    stepName: 'Confirmation',
    onNextStep: async (appData, effects, props) => {
      try {
        const account = await paymentAction(
          appData.xwToken,
          appData.role,
          appData.account.billingInfo,
          appData.currency,
          appData.order[0],
          appData.payment,
          appData.initialPurchase
        )
        // If 3D secure
        if (account._clientSecret) {
          try {
            const answer = await props.stripe.handleCardPayment(
              account._clientSecret
            )
            if (answer.error) {
              if (
                answer.error.code === 'payment_intent_authentication_failure'
              ) {
                throw new StripeError(
                  'Your payment source authentification failed. Please choose another payment source and try again.'
                )
              } else {
                throw new CriticalError(
                  `${answer.error.code} : ${answer.error.message}`
                )
              }
            }
          } catch (error) {
            await cancelPayment(
              appData.role,
              appData.xwToken,
              account._order.purchaseNumber,
              error.message
            )
            effects.previous()
            throw error
          }
        }
        return true
      } catch (error) {
        effects.handleError(error)
        return false
      }
    },
    nextText: 'Confirm and place the order'
  }
]

const withState = provideState({
  initialState: () => ({
    currentStep: 1,
    waiting: false,
    cardHolderName: ''
  }),
  effects: {
    previous() {
      this.state.currentStep = this.state.currentStep - 1
    },
    async next(effects) {
      this.state.waiting = true
      const isLastStep = last(STEPS).stepIndex === this.state.currentStep
      if (
        await STEPS[this.state.currentStep - 1].onNextStep(
          this.state,
          effects,
          this.props
        )
      ) {
        if (isLastStep) {
          window.location.replace('/#/purchase-end')
        }
        this.state.currentStep = isLastStep ? 1 : this.state.currentStep + 1
      }
      this.state.waiting = false
    },
    handleCardHolderNameChange(_, e) {
      this.state.cardHolderName = e.target.value.trimStart()
    },
    async createStripeToken(effects) {
      try {
        const { token } = await this.props.stripe.createToken({
          name:
            this.state.cardHolderName.trim() ||
            this.state.account.billingInfo.lastName
        })
        if (token) {
          effects.updatePayment({
            cardToken: token.id,
            cardLegalInfo: token.card
          })
        }
      } catch (error) {
        effects.handleError(error)
      }
    }
  }
})

const Purchase = ({ effects, state }) => (
  <Container fluid>
    <Col md={{ size: 10, offset: 1 }}>
      <Row>
        <Col md="9">
          <Stepper
            currentStep={state.currentStep}
            next={effects.next}
            previous={effects.previous}
            props={makeStepperData(state, effects, STEPS_PROPS_TEMPLATE)}
            steps={STEPS}
            waiting={state.waiting}
          />
        </Col>
        <Col md="3">
          <div style={{ marginTop: '8em' }}>
            <Summary
              billingInfo={state.account.billingInfo}
              currency={state.currency}
              order={state.order[0]}
              payment={state.payment}
            />
          </div>
        </Col>
      </Row>
    </Col>
  </Container>
)

export default injectStripe(withState(injectState(Purchase)))
