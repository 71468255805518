import styled from 'styled-components'
import { Card } from 'reactstrap'

export const CardFixedWidth = styled(Card)`
  width: 200px;
  height: 150px;
  margin-bottom: 20px;
`

export const HorizontalLine = styled.hr`
  display: inline-block;
  width: 100px;
`

export const ButtonVates = styled.button`
  background-color: #ffc107;
  border-radius: 4px;
  border: none;
  color: #343a40;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin: 2px;
  padding: 6px;
  text-align: center;
  transition: all 0.5s;
  width: 100px;

  span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }

  span:after {
    content: '\\00bb';
    opacity: 0;
    position: absolute;
    right: -20px;
    top: 0;
    transition: 0.5s;
  }

  &:hover span {
    padding-right: 25px;
  }

  &:hover span:after {
    opacity: 1;
    right: 0;
  }
`
