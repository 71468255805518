import Icon from 'react-icons-kit'
import React, { Fragment } from 'react'
import { Button, Form, Table } from 'reactstrap'
import { ic_looks_3 } from 'react-icons-kit/md/ic_looks_3'
import { ic_looks_4 } from 'react-icons-kit/md/ic_looks_4'
import { ic_looks_5 } from 'react-icons-kit/md/ic_looks_5'
import { ic_looks_6 } from 'react-icons-kit/md/ic_looks_6'
import { ic_looks_one } from 'react-icons-kit/md/ic_looks_one'
import { ic_looks_two } from 'react-icons-kit/md/ic_looks_two'
import { injectState, provideState } from 'reaclette'

import ButtonSpinner from '../../imgs/spinner.gif'
import { HorizontalLine } from '../../style/styled-components'

const stepsIcons = {
  1: <Icon icon={ic_looks_one} size={42} />,
  2: <Icon icon={ic_looks_two} size={42} />,
  3: <Icon icon={ic_looks_3} size={42} />,
  4: <Icon icon={ic_looks_4} size={42} />,
  5: <Icon icon={ic_looks_5} size={42} />,
  6: <Icon icon={ic_looks_6} size={42} />
}

const activeStepStyle = (step, currentStep) =>
  step === currentStep ? { borderBottom: '2px solid #b42626' } : undefined

const stepStyle = (step, currentStep) =>
  step > currentStep
    ? {
        color: 'rgba(0, 0, 0, 0.2)',
        fontWeight: 'bold'
      }
    : {
        color: 'rgba(0, 0, 0, 1)',
        fontWeight: 'bold'
      }

const withState = provideState({
  computed: {
    isFirstStep: (_, { currentStep }) => currentStep === 1,
    isLastStep: (_, { currentStep, steps }) => currentStep === steps.length,
    stepComponent: (_, { currentStep, steps }) =>
      steps.find(step => step.stepIndex === currentStep).stepComponent
  }
})

const Stepper = ({
  state,
  currentStep,
  lastStepText,
  next,
  previous,
  steps,
  props,
  waiting
}) => {
  const StepComponent = state.stepComponent
  const { previousText, nextText } = steps[currentStep - 1]

  return (
    <Fragment>
      <Table borderless>
        <tbody>
          <tr>
            {steps.map(step => (
              <Fragment key={step.stepIndex}>
                <td
                  className="align-middle"
                  style={activeStepStyle(step.stepIndex, currentStep)}
                >
                  {stepsIcons[step.stepIndex]}
                </td>
                <td className="align-middle">
                  <span
                    className="hvr-grow"
                    style={stepStyle(step.stepIndex, currentStep)}
                  >
                    {step.stepName}
                  </span>
                </td>

                {step.stepIndex < steps.length && (
                  <td className="align-middle">
                    <HorizontalLine />
                  </td>
                )}
              </Fragment>
            ))}
          </tr>
        </tbody>
      </Table>

      <Form
        onSubmit={e => {
          e.preventDefault()
          next()
        }}
      >
        {StepComponent ? (
          <StepComponent {...props[currentStep]} />
        ) : (
          <div className="error-component">X</div>
        )}
        <div style={{ marginTop: '32px' }}>
          <Button
            color="warning"
            className="float-right"
            type="submit"
            disabled={waiting}
          >
            <span style={{ fontWeight: 'bold' }}>
              {nextText ? nextText : 'Next'}
              &raquo;
            </span>
            {waiting && (
              <img
                src={ButtonSpinner}
                alt="button spinner"
                height="20"
                width="20"
              />
            )}
          </Button>
          <Button
            color="secondary"
            className="float-left"
            onClick={previous}
            disabled={state.isFirstStep}
          >
            <span style={{ fontWeight: 'bold' }}>
              &laquo; {previousText ? previousText : 'Previous'}
            </span>
          </Button>
        </div>
      </Form>
    </Fragment>
  )
}

export default withState(injectState(Stepper))
