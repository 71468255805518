import React from 'react'
import Icon from 'react-icons-kit'
import { Card, CardText, Col, Row } from 'reactstrap'
import { ic_credit_card } from 'react-icons-kit/md/ic_credit_card'
import { injectState, provideState } from 'reaclette'

const withState = provideState({
  effects: {},
  computed: {
    cardLegalInfo: ({ account }) => account.stripe.card
  }
})

const CreditCardRecap = ({ state }) => (
  <Card body>
    <CardText tag="div">
      <h3>
        <Icon
          icon={ic_credit_card}
          size={28}
          style={{ verticalAlign: 'middle' }}
        />{' '}
        Your Credit Card{' '}
      </h3>
      <hr />
      <Row>
        <Col>
          <span className="text-muted">Card number</span>
          <span className="float-right">
            {(state.cardLegalInfo &&
              state.cardLegalInfo.last4 &&
              `**** **** **** ${state.cardLegalInfo.last4}`) ||
              '-'}
          </span>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <span className="text-muted">Card holder</span>
          <span className="float-right">
            {(state.cardLegalInfo && state.cardLegalInfo.name) || '-'}
          </span>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <span className="text-muted">Card type</span>
          <span className="float-right">
            {(state.cardLegalInfo && state.cardLegalInfo.brand) || '-'}
          </span>
        </Col>
      </Row>
    </CardText>
  </Card>
)

export default withState(injectState(CreditCardRecap))
