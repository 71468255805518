import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const actionValidate = (modalAction, modalActionArgs, close) => {
  modalAction.apply(this, modalActionArgs)
  close()
}

const ConfirmModal = ({
  close,
  modalAction,
  modalActionArgs,
  modalBody = 'Are you sure you want to continue ?',
  modalStatus
}) => (
  <Modal isOpen={modalStatus}>
    <ModalHeader>Confirmation required</ModalHeader>
    <ModalBody>{modalBody}</ModalBody>
    <ModalFooter>
      <Button
        color="primary"
        onClick={() => actionValidate(modalAction, modalActionArgs, close)}
      >
        Validate
      </Button>{' '}
      <Button color="secondary" onClick={close}>
        Cancel
      </Button>
    </ModalFooter>
  </Modal>
)

export default ConfirmModal
