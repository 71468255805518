import '../../../style/radio-buttons.css'
import React, { Fragment } from 'react'
import {
  Button,
  ButtonGroup,
  Col,
  Collapse,
  Row,
  UncontrolledTooltip
} from 'reactstrap'
import { isEmpty } from 'lodash'
import { injectState, provideState } from 'reaclette'

import { getPlanFormula, PAID_PERIOD, SUBSCRIPTION } from '../../purchase-utils'

const withState = provideState({
  initialState: () => ({
    planFormula: {
      [SUBSCRIPTION]: [],
      [PAID_PERIOD]: []
    },
    activeFormula: undefined
  }),
  effects: {
    async initialize(effects) {
      await effects.getPlanFormula()
    },
    async getPlanFormula(effects) {
      try {
        const planFormula = await getPlanFormula(
          this.props.productId,
          this.state.role,
          this.state.account
        )
        this.state.planFormula = planFormula
        effects.savePlanFormula(planFormula)

        // If the customer has already chosen his plan
        if (this.state._order.paymentModel.type) {
          this.state.activeFormula = this.state._order.paymentModel.type
        } else {
          // If it's an upgrade, we don't provide to change the payment method (we keep the same payment method as the initial purchase)
          this.state.activeFormula = this.state.initialOrder
            ? this.state.initialOrder.paymentModel.type
            : isEmpty(planFormula.subscription)
            ? PAID_PERIOD
            : SUBSCRIPTION

          effects.chooseFormula(this.state.activeFormula)
        }
      } catch (error) {
        effects.handleError(error)
      }
    }
  },
  computed: {
    disableSubscription: ({ planFormula, initialOrder }) =>
      isEmpty(planFormula[SUBSCRIPTION]) ||
      (initialOrder && initialOrder.paymentModel.type === PAID_PERIOD),
    disablePaidPeriod: ({ planFormula, initialOrder }) =>
      isEmpty(planFormula[PAID_PERIOD]) ||
      (initialOrder && initialOrder.paymentModel.type === SUBSCRIPTION)
  }
})

const Subscription = ({ effects, state, productId }) => (
  <Fragment>
    <Row>
      <Col>
        <p className="text-muted">Choose your plan</p>
      </Col>
    </Row>
    <Row>
      <Col>
        <ButtonGroup>
          <Button
            type="button"
            active={state.activeFormula === SUBSCRIPTION}
            color="dark"
            id="commitment-tooltip-id"
            outline
            style={{ width: '200px' }}
            disabled={state.disableSubscription}
          >
            Subscription
          </Button>
          <Button
            type="button"
            active={state.activeFormula === PAID_PERIOD}
            color="dark"
            outline
            style={{ width: '200px' }}
            disabled={state.disablePaidPeriod}
          >
            Paid period
          </Button>
        </ButtonGroup>
        <UncontrolledTooltip placement="top" target="commitment-tooltip-id">
          There is no commitment in Xen Orchestra, you can cancel the renewal at
          any time
        </UncontrolledTooltip>
      </Col>
    </Row>
    <br />
    <Collapse isOpen={state.activeFormula === SUBSCRIPTION}>
      {/* <ul className="list" style={{ listStyle: 'none' }}>
        {state.planFormula &&
          state.planFormula.subscription.map((subscription, index) => (
            <li className="list__item" key={index}>
              <input
                required={state.activeFormula === SUBSCRIPTION}
                type="radio"
                className="radio-btn"
                name={`${productId}-sub-choice`}
                id={`${productId}-${index}-sub-btn`}
                onChange={() => {
                  effects.updateOrder(productId, {
                    planFormula: { type: SUBSCRIPTION, ...subscription }
                  })
                  effects.choosePeriod(subscription.year)
                }}
                checked={
                  (state._order.paymentModel.type === SUBSCRIPTION &&
                    state._order.paymentModel.year === subscription.year) ||
                  false
                }
              />
              <label
                htmlFor={`${productId}-${index}-sub-btn`}
                className="label"
              >
                <span className="badge badge-dark" style={{ fontSize: '16px' }}>
                  <Currency
                    quantity={subscription.tax.total}
                    currency={state.currency}
                  />
                </span>
                {subscription.year > 0 &&
                  productId !== 'xo-proxy' &&
                  productId !== 'xosan' && (
                    <span
                      className="badge badge-warning"
                      style={{ fontSize: '14px' }}
                    >
                      SAVE 10%
                    </span>
                  )}
                &nbsp;
                <span className="text-muted">
                  per {subscription.year === 0 ? 'month' : 'year'}
                </span>
              </label>
            </li>
          ))}
      </ul> */}
    </Collapse>
    <Collapse
      isOpen={state.activeFormula === PAID_PERIOD}
      style={{ marginLeft: '200px' }}
    >
      {/* <ul className="list" style={{ listStyle: 'none' }} required>
        {state.planFormula &&
          state.planFormula[PAID_PERIOD].map((prepaid, index) => (
            <li className="list__item" key={index}>
              <input
                required={state.activeFormula === PAID_PERIOD}
                type="radio"
                className="radio-btn"
                name={`${productId}-prepaid-choice`}
                id={`${productId}-${index}-prepaid-btn`}
                checked={
                  (state._order.paymentModel.type === PAID_PERIOD &&
                    state._order.paymentModel.year === prepaid.year) ||
                  false
                }
                onChange={() => {
                  effects.updateOrder(productId, {
                    planFormula: { type: PAID_PERIOD, ...prepaid }
                  })
                  effects.choosePeriod(prepaid.year)
                }}
              />
              <label
                htmlFor={`${productId}-${index}-prepaid-btn`}
                className="label"
              >
                {prepaid.year} year{prepaid.year > 1 && 's'} &nbsp; &nbsp;
                <span className="badge badge-dark" style={{ fontSize: '16px' }}>
                  <Currency
                    quantity={prepaid.tax.total}
                    currency={state.currency}
                  />
                </span>
                {!isEmpty(prepaid.discount) && (
                  <span
                    className="badge badge-warning"
                    style={{ fontSize: '14px' }}
                  >
                    SAVE{' '}
                    <Currency
                      quantity={prepaid.discount.amount * -1}
                      currency={state.currency}
                    />
                  </span>
                )}
              </label>
            </li>
          ))}
      </ul> */}
    </Collapse>
  </Fragment>
)

export default withState(injectState(Subscription))
