import React, { Fragment } from 'react'
import { Badge, Col, Collapse, Row } from 'reactstrap'
import { injectState, provideState } from 'reaclette'
import { ic_swap_horiz } from 'react-icons-kit/md/ic_swap_horiz'
import Icon from 'react-icons-kit'
import { isUpgradable, Order } from '@vates/www-xo-utils'
import Currency from 'react-currency-formatter'
import { getCartWrapper } from '@vates/sales-lib'
import { isEmpty } from 'lodash'

import {
  getProductsList,
  PRODUCTS_ICON,
  PRODUCTS_NAME
} from '../../purchase-utils'
import { CardFixedWidth } from '../../../style/styled-components'
import Subscription from './subscription'

const withState = provideState({
  initialState: () => ({
    allProducts: [],
    availableProducts: [],
    initialOrderProductId: undefined,
    quantity: 1
  }),
  effects: {
    async initialize(effects) {
      await effects.getProducts()
    },
    async getProducts(effects) {
      try {
        const allProducts = await getProductsList(this.state.role)
        this.state.allProducts = allProducts

        if (this.state.initialOrder) {
          const initialCart = getCartWrapper(this.state.initialOrder)
          const initialOrderProduct = Order.getUniqProductFromCart(
            initialCart.products
          )

          this.state.quantity = initialOrderProduct.quantity
          this.state.initialOrderProductId = initialOrderProduct.productId
          this.state.availableProducts = allProducts
            .filter(product => product.isBuyable)
            .filter(product =>
              isUpgradable(initialOrderProduct.productId, product.id)
            )
        }
      } catch (error) {
        effects.handleError(error)
      }
    },
    async addOrder(effects, product) {
      effects._addToOrder(product, this.state.quantity)
    }
  }
})

const UpgradePlan = ({ state, effects }) => (
  <Fragment>
    <Row>
      <Col>
        <h4>
          Product required details{' '}
          {state.initialOrderProductId && (
            <span className="text-info">
              (Upgrade from {state.initialOrderProductId})
            </span>
          )}
        </h4>
        <hr />
      </Col>
    </Row>
    <Row>
      <Col>
        <p className="text-muted">Choose your product</p>
      </Col>
    </Row>
    <h5>
      {!isEmpty(state.uniqueProduct) && (
        <Fragment>
          {PRODUCTS_ICON[state.uniqueProduct.productId] && (
            <img
              alt="*"
              height="30"
              width="30"
              src={PRODUCTS_ICON[state.uniqueProduct.productId]}
            />
          )}
          <Badge color="light" style={{ fontSize: '16px' }}>
            <span style={{ color: '#b42626' }}>
              {PRODUCTS_NAME[state.uniqueProduct.productId]}
            </span>
          </Badge>
        </Fragment>
      )}
      <Icon
        icon={ic_swap_horiz}
        size={25}
        style={{ verticalAlign: 'middle' }}
      />
      &nbsp;
      {!isEmpty(state.uniqueProduct) ? (
        <span
          style={{ cursor: 'pointer' }}
          className="change-plan"
          onClick={effects.resetCart}
        >
          change plan
        </span>
      ) : (
        <span>Select a product</span>
      )}
    </h5>
    <Collapse isOpen={isEmpty(state.uniqueProduct)}>
      <Row>
        {state.availableProducts.map(product => (
          <Col md="3" key={product.id}>
            <CardFixedWidth
              className="hvr-grow"
              body
              onClick={() => effects.addOrder(product)}
              style={{ cursor: 'pointer' }}
            >
              <p
                style={{ color: '#01337f', fontWeight: 'bold' }}
                className="text-center"
              >
                {product.name}
              </p>
              {product.montly && product.montly.amount > 0 && (
                <div className="text-center">
                  <h4>
                    <Badge color="light">
                      <Currency
                        quantity={product.montly.amount}
                        currency={state.currency}
                      />
                      /mo.
                    </Badge>
                  </h4>
                </div>
              )}
              {product.yearly && product.yearly.amount > 0 && (
                <div className="text-center">
                  <h4>
                    <Badge color="light">
                      <Currency
                        quantity={product.yearly.amount}
                        currency={state.currency}
                      />
                      /year
                    </Badge>
                  </h4>
                </div>
              )}
            </CardFixedWidth>
          </Col>
        ))}
      </Row>
    </Collapse>
    {!isEmpty(state.uniqueProduct) && (
      <Subscription productId={state.uniqueProduct.productId} />
    )}
  </Fragment>
)

export default withState(injectState(UpgradePlan))
