import '../../../style/radio-buttons.css'
import Currency from 'react-currency-formatter'
import Icon from 'react-icons-kit'
import React, { Fragment } from 'react'
import { Badge, Col, Collapse, Row } from 'reactstrap'
import { ic_swap_horiz } from 'react-icons-kit/md/ic_swap_horiz'
import { injectState, provideState } from 'reaclette'
import { isUpgradable } from '@vates/www-xo-utils'

import BindingChoice from './bindingChoice'
import Subscription from './subscription'
import { CardFixedWidth } from '../../../style/styled-components'
import { getProductsList } from '../../purchase-utils'

const withState = provideState({
  initialState: () => ({
    products: []
  }),
  effects: {
    async initialize(effects) {
      await effects.getProducts()
      await effects.setPreSelectedProduit()
    },
    async getProducts(effects) {
      try {
        this.state.products = await getProductsList(this.props.role)
      } catch (error) {
        effects.handleError(error)
      }
    },
    async setPreSelectedProduit(effects) {
      if (this.props.preSelectedProduct) {
        const product = this.state.products.find(
          product => product.id === this.props.preSelectedProduct
        )
        effects.addToOrder(product)
      }
    }
  },
  computed: {
    productsBuyable: ({ products, initialPurchase }) => {
      const productsBuyable = products.filter(product => product.isBuyable)
      // If initialPurchase, we only return the upgradable products
      if (initialPurchase && initialPurchase.id) {
        return productsBuyable.filter(product =>
          isUpgradable(initialPurchase.id, product.id)
        )
      }

      return productsBuyable
    },
    selectedProduct: ({ order }) => order[0]
  }
})

const PlanDetails = ({
  effects,
  state,
  account,
  addToOrder,
  country,
  currency,
  initialPurchase,
  order,
  resetOrder,
  role,
  updateOrder,
  xwToken
}) => (
  <Fragment>
    <Row>
      <Col>
        <h4>
          Product required details{' '}
          {initialPurchase && (
            <span className="text-info">
              (Upgrade from {initialPurchase.id})
            </span>
          )}
        </h4>

        <hr />
      </Col>
    </Row>
    <Row>
      <Col>
        <p className="text-muted">Choose your product</p>
      </Col>
    </Row>
    <h5>
      {state.selectedProduct && (
        <Fragment>
          {state.selectedProduct.icon && (
            <img
              alt="logo product"
              height="30"
              src={state.selectedProduct.icon}
            />
          )}
          <Badge color="light" style={{ fontSize: '16px' }}>
            <span style={{ color: '#b42626' }}>
              {state.selectedProduct.name}
            </span>
          </Badge>
        </Fragment>
      )}
      <Icon
        icon={ic_swap_horiz}
        size={25}
        style={{ verticalAlign: 'middle' }}
      />
      &nbsp;
      {state.selectedProduct ? (
        <span
          style={{ cursor: 'pointer' }}
          className="change-plan"
          onClick={resetOrder}
        >
          change plan
        </span>
      ) : (
        <span>Select a product</span>
      )}
    </h5>
    <Collapse isOpen={!state.selectedProduct}>
      <Row>
        {state.productsBuyable.map(product => (
          <Col md="3" key={product.id}>
            <CardFixedWidth
              className="hvr-grow"
              body
              onClick={() => addToOrder(product)}
              style={{ cursor: 'pointer' }}
            >
              <p
                style={{ color: '#01337f', fontWeight: 'bold' }}
                className="text-center"
              >
                {product.name}
              </p>
              {product.montly && product.montly.amount > 0 && (
                <div className="text-center">
                  <h4>
                    <Badge color="light">
                      <Currency
                        quantity={product.montly.amount}
                        currency={currency}
                      />
                      /mo.
                    </Badge>
                  </h4>
                </div>
              )}
              {product.yearly && product.yearly.amount > 0 && (
                <div className="text-center">
                  <h4>
                    <Badge color="light">
                      <Currency
                        quantity={product.yearly.amount}
                        currency={currency}
                      />
                      /year
                    </Badge>
                  </h4>
                </div>
              )}
            </CardFixedWidth>
          </Col>
        ))}
      </Row>
    </Collapse>

    {order.map(product => (
      <Fragment key={product.id}>
        <Subscription
          account={account}
          country={country}
          currency={currency}
          initialPurchase={initialPurchase}
          product={product}
          role={role}
          updateOrder={updateOrder}
        />
        <br />
        <BindingChoice
          initialPurchase={initialPurchase}
          product={product}
          role={role}
          updateOrder={updateOrder}
          xwToken={xwToken}
        />
        <br />
      </Fragment>
    ))}
  </Fragment>
)

export default withState(injectState(PlanDetails))
