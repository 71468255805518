import React, { Fragment } from 'react'
import { Col, FormGroup, Row } from 'reactstrap'

import CreditCard from '../new-purchase/payment/credit-card'
import WireTransfer from '../new-purchase/payment/wire-transfer'

const ManualOrderPayment = () => (
  <Fragment>
    <Row>
      <Col>
        <h4>Payment method</h4>
        <hr />
      </Col>
    </Row>
    <Row>
      <Col>
        <FormGroup tag="fieldset">
          <WireTransfer />
          <br />
          <CreditCard />
        </FormGroup>
      </Col>
    </Row>
  </Fragment>
)

export default ManualOrderPayment
