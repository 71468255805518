import React, { Fragment } from 'react'
import { Col, FormGroup, Row } from 'reactstrap'
import { injectState } from 'reaclette'

import CreditCard from './credit-card'
import WireTransfer from './wire-transfer'

import { PAID_PERIOD, SUBSCRIPTION } from '../../purchase-utils'

const Payment = ({ state }) => (
  <Fragment>
    <Row>
      <Col>
        <h4>Payment method</h4>
        <hr />
      </Col>
    </Row>
    <Row>
      <Col>
        <FormGroup tag="fieldset">
          {state._order.paymentModel.type === SUBSCRIPTION && <CreditCard />}
          <br />
          {state._order.paymentModel.type === PAID_PERIOD && (
            <>
              <WireTransfer />
              <br />
              {!state._order.onUpgrade && <CreditCard />}
            </>
          )}
        </FormGroup>
      </Col>
    </Row>
  </Fragment>
)

export default injectState(Payment)
