import React from 'react'
import {
  Col,
  Collapse,
  FormGroup,
  Input,
  Label,
  Row,
  Card,
  CardHeader,
  CardBody,
  CardText
} from 'reactstrap'
import { injectState, provideState } from 'reaclette'

import NewCreditCardForm from './new-credit-card-form'
import PaymentIcon from '../../../imgs/payment-icon.png'
import { STRIPE } from '../../purchase-utils'

const withState = provideState({
  effects: {
    initialize(effects) {
      effects.updatePayment({ cardToken: undefined, cardLegalInfo: undefined })
    }
  },
  computed: {
    previousCardInfo: ({ account }) =>
      account && account.stripe && account.stripe.card
  }
})

const CreditCard = ({ state, effects }) => (
  <Card>
    <CardHeader>
      <Row>
        <Col>
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                name="radio-method"
                checked={state.payment.paymentMethod === STRIPE}
                onChange={() =>
                  effects.updatePayment({ paymentMethod: STRIPE })
                }
                required
              />
              Credit Card
            </Label>
          </FormGroup>
        </Col>
        <Col>
          <img
            className="float-right"
            height="45"
            width="260"
            src={PaymentIcon}
            alt="Visa Card"
          />
        </Col>
      </Row>
    </CardHeader>
    <Collapse isOpen={state.payment.paymentMethod === STRIPE}>
      <CardBody>
        <CardText tag="div">
          {state.previousCardInfo && (
            <FormGroup tag="card-options">
              <ul className="list">
                <li className="list__item">
                  <FormGroup check>
                    <input
                      checked={state.payment.usePreviousCard === true}
                      className="radio-btn"
                      id={`previous-card-radio-bottom`}
                      name="card-options"
                      onChange={() =>
                        effects.updatePayment({
                          usePreviousCard: true,
                          cardToken: undefined,
                          cardLegalInfo: undefined
                        })
                      }
                      required={state.payment.paymentMethod === STRIPE}
                      type="radio"
                    />
                    <label
                      htmlFor={`previous-card-radio-bottom`}
                      className="label"
                    >
                      Use your card:
                      <span className="text-muted text-font-bold">
                        **** **** **** {state.previousCardInfo.last4}
                      </span>
                    </label>
                  </FormGroup>
                </li>
                <li className="list__item">
                  <FormGroup check>
                    <input
                      checked={
                        state.payment.usePreviousCard === false ||
                        !state.previousCardInfo
                      }
                      className="radio-btn"
                      id={`new-card-radio-bottom`}
                      name="card-options"
                      onChange={() =>
                        effects.updatePayment({
                          usePreviousCard: false
                        })
                      }
                      required={state.payment.paymentMethod === STRIPE}
                      type="radio"
                    />
                    <label htmlFor={`new-card-radio-bottom`} className="label">
                      Add new card
                    </label>
                  </FormGroup>
                </li>
              </ul>
            </FormGroup>
          )}
          {(state.payment.usePreviousCard === false ||
            !state.previousCardInfo) && <NewCreditCardForm />}
        </CardText>
      </CardBody>
    </Collapse>
  </Card>
)

export default withState(injectState(CreditCard))
