import Cookies from 'js-cookie'
import React, { Fragment } from 'react'
import queryString from 'query-string'
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { ToastContainer, toast } from 'react-toastify'
import {
  getCurrency,
  getSubCompany,
  guessCurrency,
  Order as OrderUtils,
  findPurchaseUser,
  isExtendedPurchase
} from '@vates/www-xo-utils'
import { find } from 'lodash'
import { injectState, provideState } from 'reaclette'
import { StripeProvider, Elements } from 'react-stripe-elements'
import * as XwApiErrors from '@vates/xw-api-errors'
import moment from 'moment'
import {
  Order,
  upgradeOrder,
  extendOrder,
  getCartWrapper
} from '@vates/sales-lib'

import { generateCrossAuthToken, getApi, verifyCrossAuthToken } from '../api'
import * as Utils from '../utils'
import { getProductsList, updatedPlanFormula } from './purchase-utils'
import AuthenticationForm from './authentication-form'
import ErrorPage from './error-page'
import ExtendOrder from './new-purchase/extend-order'
import ExtendPurchase from './new-purchase/extend-purchase'
import Footer from './main/footer'
import Header from './main/header'
import Loader from './components/loader'
import ManualOrder from './manual-order'
import NewPurchase from './new-purchase'
import Purchase from './purchase'
import PurchaseEnd from './purchase/purchase-end'
import ResellerModal from './ResellerModal'
import UpgradeOrder from './new-purchase/upgrade-order'

const PrivateRoute = injectState(
  ({ state, component: Component, ...props }) => (
    <Route
      render={routeProps =>
        state.logged ? (
          <Component {...routeProps} {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: routeProps.location }
            }}
          />
        )
      }
    />
  )
)

const getCountryFromIpAddress = async () => {
  try {
    const response = await fetch('https://ipinfo.io/json')
    const { country } = await response.json()
    return country
  } catch (error) {
    console.error(error)
  }
}

const withState = provideState({
  initialState: () => ({
    account: undefined,
    config: undefined,
    currency: 'USD',
    forceRole: undefined,
    forceStep: undefined, // Used to force the state of the stepper (when you add an existing quote)
    guessedCountry: undefined,
    initialOrder: undefined, //Use to know the initial order's data when you continue/edit an Order
    initialPurchase: undefined, //Use to know the purchase when you upgrade an existing purchase
    order: [], //The data that the customer selects at different stages through the stepper
    _order: undefined,
    orderId: undefined, //Save order.id when customers place an Order
    payment: {},
    preSelectedProduct: undefined, //Save the pre-selected product passed in the URL
    products: [],
    planFormula: undefined,
    selfbound: undefined,
    waiting: true,
    waitingLogin: false,
    isOrderSaved: undefined //Use to know if an Order is saved after modifications
  }),
  effects: {
    async initialize(effects) {
      try {
        const {
          cat,
          extendOrderId,
          extendPurchaseNumber,
          orderId,
          product,
          purchaseNumber,
          role,
          upgradeOrder
        } = queryString.parse(window.location.hash.slice(2))
        await effects.getConfig()
        await effects.logIn(cat)
        /*
         * We need to have an account logged to upgrade an old purchase (purchaseNumber) or to continue an order (orderId)
         */
        if (this.state.account) {
          if (purchaseNumber) {
            this.state.initialPurchase = Utils.getPurchaseFromNumber(
              purchaseNumber,
              role || this.state.role,
              this.state.account
            )
            // force the role for the customers who can upgrade purchases reseller or regular purchases
            this.state.forceRole = role
            window.location.replace('/#/purchase')
          } else if (extendPurchaseNumber) {
            const purchase = Utils.getPurchaseFromNumber(
              extendPurchaseNumber,
              role || this.state.role,
              this.state.account
            )
            if (isExtendedPurchase(purchase)) {
              effects.notify('error', 'Your purchase is already extended')
            } else {
              this.state.initialPurchase = purchase

              const productsList = await getProductsList(this.state.role)
              const product = productsList.find(
                product => product.id === this.state.initialPurchase.id
              )

              const selfbound =
                findPurchaseUser(this.state.initialPurchase) ===
                this.state.xwToken

              this.state._order = new Order({
                currency: this.state.currency,
                role: role || this.state.role,
                selfbound,
                subCompany: this.state.subCompany
              })
              effects._addToOrder(product, purchase.quantity || 1)
              effects.addToOrder({
                ...product,
                quantity: purchase.quantity
              })

              this.state.products = productsList

              this.state.forceRole = role
              window.location.replace('/#/extend-purchase')
            }
          } else if (extendOrderId) {
            try {
              this.state.waiting = true
              effects.resetOrder()
              const initialOrder = await getApi(this.state.role).getOrder(
                extendOrderId
              )
              getCartWrapper(initialOrder)
              if (OrderUtils.isExtendedOrder(initialOrder)) {
                effects.notify('error', 'Your order is already extended')
              } else {
                this.state.initialOrder = initialOrder

                effects.setOrderId(initialOrder.id)

                const productsList = await getProductsList(this.state.role)
                const uniqProduct = OrderUtils.getUniqProductFromCart(
                  initialOrder.cart
                )
                const product = productsList.find(
                  product => product.id === uniqProduct.productId
                )
                effects.addToOrder({
                  ...product,
                  quantity: uniqProduct.quantity
                })
                this.state.selfbound =
                  initialOrder.endUser &&
                  initialOrder.endUser.token === this.state.xwToken
                this.state.products = productsList

                this.state.forceRole = initialOrder.role

                const newOrder = extendOrder(initialOrder)
                newOrder.subCompany = this.state.subCompany
                await effects.refreshOrder(newOrder)

                window.location.replace('/#/extend-order')
              }
            } catch (error) {
              await effects.handleError(error)
            } finally {
              this.state.waiting = false
            }
          } else if (orderId) {
            const order = await getApi(this.state.role).getOrder(orderId)
            if (order.isManual) {
              await effects.continueManualOrder(order)
            } else {
              await effects.continueOrder(order)
            }
          } else if (upgradeOrder) {
            await effects.initUpgradeOrder(upgradeOrder)
          } else {
            this.state.preSelectedProduct = product
            this.state.selfbound =
              this.state.role === 'reseller' ? false : undefined
          }
        }

        this.state.guessedCountry = await getCountryFromIpAddress()
      } catch (error) {
        await effects.handleError(error)
      } finally {
        this.state.waiting = false
        this.state._order = {
          ...this.state._order,
          role: this.state.role,
          subCompany: this.state.subCompany
        }
      }
    },
    async getConfig(effects) {
      try {
        this.state.config = await getApi().getClientConf([
          'accountFrontEndUrl',
          'baseUrl',
          'companyUrl',
          'partnerPortalFrontEndUrl',
          'stripePublicKey'
        ])
      } catch (error) {
        window.location.replace('/#/error')
        throw error
      }
    },
    async logIn(effects, cat) {
      try {
        this.state.waitingLogin = true
        const jwt = cat || Cookies.get('accessToken')
        if (jwt) {
          const customerId = await verifyCrossAuthToken(decodeURIComponent(jwt))
          if (customerId) {
            const account = await getApi().getAccount(customerId)
            effects.setAccessToken(customerId)
            effects.storeAccount(account)
            effects.updateCurrency()
            effects.resetOrder()
            window.location.replace('/#/new-purchase')
          }
        }
      } catch (error) {
        Cookies.remove('accessToken')
        window.location.replace('/#/login')
      } finally {
        this.state.waitingLogin = false
      }
    },
    async signIn(effects, email, password) {
      try {
        this.state.waitingLogin = true
        const account = await getApi().authenticate(email, password)
        effects.setAccessToken(account.token)
        effects.storeAccount(account)
        effects.updateCurrency()
        effects.resetOrder()
        window.location.replace('/#/new-purchase')
      } catch (error) {
        await effects.handleError(error)
      } finally {
        this.state.waitingLogin = false
      }
    },
    async setAccessToken(_, customerId) {
      const DAYS = 30
      const eat = Date.now() + 3.6e6 * 24 * DAYS // 30 days
      const accessToken = await generateCrossAuthToken(customerId, eat)
      if (accessToken) {
        Cookies.set('accessToken', accessToken, { expires: DAYS })
      }
    },
    storeAccount(_, account) {
      this.state.account = account
    },
    handleSelfbound(effects, selfbound) {
      this.state.selfbound = selfbound
      this.state._order = { ...this.state._order, selfbound }
      effects.handleChangeOrderSaved(false)
    },
    async refreshAccountInfo(effects) {
      const account = await getApi().getAccount(this.state.xwToken)
      this.state.account = account
    },
    async refreshOrder(_, order) {
      const cart = getCartWrapper(order)
      const date = order.fromQuote && order.fromQuote.quoteCreationDate
      cart.compute(
        this.state.country,
        await getApi().getPrices({ date }),
        await getApi().getDiscountsConfiguration()
      )
      this.state._order = { ...order }
    },
    updateCurrency(effects) {
      const currency =
        getCurrency(this.state.account) ||
        guessCurrency(this.state.account, this.state.country) ||
        'USD'

      if (this.state._order && !this.state._order.id) {
        this.state._order = { ...this.state._order, currency }
      }

      this.state.currency = currency
    },
    notifyPricingModification(effects) {
      effects.notify(
        'info',
        'Your modification have lead to a pricing modification. The price has been updated accordingly',
        'pricing-modification'
      )
    },
    async addToOrder(effects, productData) {
      const order = this.state.order
      order.push(productData)
      this.state.order = [...order]
      effects.handleChangeOrderSaved(false)
    },
    async _addToOrder(effects, productData, quantity = 1) {
      const cart = getCartWrapper(this.state._order)
      cart.addProduct(productData, quantity)
      const date =
        this.state._order.fromQuote &&
        this.state._order.fromQuote.quoteCreationDate
      cart.compute(
        this.state.country,
        await getApi().getPrices({ date }),
        await getApi().getDiscountsConfiguration()
      )
      this.state._order = { ...this.state._order }
      effects.handleChangeOrderSaved(false)
    },
    setOrderId(_, orderId) {
      this.state.orderId = orderId
    },
    async continueOrder(effects, order, forceStep = undefined) {
      try {
        this.state.waiting = true
        effects.resetOrder()
        if (order.onUpgrade) {
          await effects.continueUpgradeOrder(order)
        } else if (OrderUtils.isUpdatable(order)) {
          this.state.initialOrder = order
          this.state.forceStep = forceStep
          effects.setOrderId(order.id)
          this.state.selfbound =
            order.endUser && order.endUser.token === this.state.xwToken
          this.state.forceRole = order.role // Set the role according to whether the order has been placed as a reseller or purchaser

          const date = order.fromQuote && order.fromQuote.quoteCreationDate // Use to get product prices based on the date of the quote
          const uniqProduct = OrderUtils.getUniqProductFromCart(order.cart)
          const productData = await Utils.getProductData(
            uniqProduct.productId,
            this.state.role,
            date
          )
          if (productData) {
            effects.addToOrder(productData)
          }

          const planFormula = await Utils.getPlanFormulaFromOrderId(
            order,
            this.state.role,
            this.state.account,
            date
          )
          if (planFormula) {
            effects.updateOrder(uniqProduct.productId, {
              quantity: uniqProduct.quantity,
              planFormula: {
                type: order.paymentModel.type,
                ...planFormula
              }
            })
          }

          const _order = new Order(this.state.initialOrder)
          if (!_order.subCompany) {
            _order.subCompany = this.state.subCompany
          }
          this.state._order = _order
          getCartWrapper(this.state._order).compute(
            this.state.country,
            await getApi().getPrices({ date }),
            await getApi().getDiscountsConfiguration()
          )

          if (
            this.state._order.stripeSub ||
            this.state._order.status === OrderUtils.ORDER_STATUS.CARD_EXPECTED
          ) {
            effects.updatePayment({
              paymentMethod: 'stripe',
              usePreviousCard:
                this.state.account.stripe && this.state.account.stripe.card
            })
          }
          effects.handleChangeOrderSaved(true)
        }
      } catch (error) {
        await effects.handleError(error)
      } finally {
        this.state.waiting = false
      }
    },
    async continueManualOrder(effects, order) {
      try {
        this.state.waiting = true
        effects.resetOrder()
        if (OrderUtils.isUpdatable(order)) {
          this.state.initialOrder = order
          this.state.selfbound =
            order.endUser && order.endUser.token === this.state.xwToken
          this.state.forceRole = order.role // Set the role according to whether the order has been placed as a reseller or purchaser
          effects.setOrderId(order.id)
          effects.addToOrder({ planFormula: { type: 'paidPeriod' } })

          if (
            order.stripeSub ||
            order.status === OrderUtils.ORDER_STATUS.CARD_EXPECTED ||
            order.status ===
              OrderUtils.ORDER_STATUS.CARD_ONESHOT_PAYMENT_PENDING
          ) {
            effects.updatePayment({
              paymentMethod: 'stripe'
            })
          }
          effects.handleChangeOrderSaved(true)
          const _order = new Order(this.state.initialOrder)
          if (!_order.subCompany) {
            _order.subCompany = this.state.subCompany
          }
          this.state._order = _order
          window.location.replace('/#/manual-order')
        }
      } catch (error) {
        await effects.handleError(error)
      } finally {
        this.state.waiting = false
      }
    },
    async continueUpgradeOrder(effects, order, forceStep = undefined) {
      try {
        this.state.waiting = true
        if (!order.waitingUpgrade || !order.waitingUpgrade.initialOrderId) {
          throw new XwApiErrors.ForbiddenAction('Upgrade not initialized')
        }
        if (!OrderUtils.canContinuePurchasing(order)) {
          throw new XwApiErrors.ForbiddenAction(
            'This upgrade cannot be continued'
          )
        }
        const initialOrder = await getApi(this.state.role).getOrder(
          order.waitingUpgrade.initialOrderId
        )

        if (moment(order.waitingUpgrade.upgradeDate, 'x').isBefore(moment())) {
          order = await getApi(this.state.role).refreshUpgradeOrder(
            this.state.xwToken,
            order.id
          )
        }

        effects.setOrderId(order.id)
        this.state.initialOrder = initialOrder
        await effects.refreshOrder(order)
        effects.handleChangeOrderSaved(true)

        window.location.replace('/#/upgrade-order')
      } catch (error) {
        await effects.handleError(error)
      } finally {
        this.state.waiting = false
      }
    },
    async initUpgradeOrder(effects, orderId) {
      try {
        this.state.waiting = true
        const initialOrder = await getApi(this.state.role).getOrder(orderId)
        getCartWrapper(initialOrder)

        if (initialOrder.customerId !== this.state.xwToken) {
          throw new XwApiErrors.ForbiddenAction(
            'You can only upgrade your orders'
          )
        }
        if (OrderUtils.isUpgradableOrder(initialOrder)) {
          this.state.initialOrder = initialOrder

          effects.setOrderId(initialOrder.id)
          this.state.selfbound =
            initialOrder.endUser &&
            initialOrder.endUser.token === this.state.xwToken
          this.state.forceRole = initialOrder.role // Set the role according to whether the order has been placed as a reseller or purchaser

          let startPeriod
          let endPeriod
          if (
            initialOrder.paymentModel.type === 'subscription' &&
            initialOrder.stripeSub.sub
          ) {
            const period = await getApi().getPeriodOfSubscription(
              initialOrder.stripeSub.sub
            )
            startPeriod = period.startPeriod
            endPeriod = period.endPeriod
          }
          const newOrder = upgradeOrder(initialOrder, {
            startPeriod,
            endPeriod
          })

          this.state._order = newOrder
          window.location.replace('/#/upgrade-order')
        }
      } catch (error) {
        await effects.handleError(error)
      } finally {
        this.state.waiting = false
      }
    },
    updateOrder(effects, productId, data) {
      this.state.order = this.state.order.map(product => {
        if (product.id === productId) {
          return {
            ...product,
            ...data
          }
        }
        return product
      })
      effects.handleChangeOrderSaved(false)
    },
    async updateBillingInfo(effects, data) {
      this.state.account = {
        ...this.state.account,
        billingInfo: {
          ...this.state.account.billingInfo,
          ...data
        }
      }
      if (data.hasOwnProperty('country')) {
        effects.updatePlanFormula()
        effects.updateCurrency()
        const date =
          this.state._order.fromQuote &&
          this.state._order.fromQuote.quoteCreationDate
        getCartWrapper(this.state._order).compute(
          this.state.country,
          await getApi().getPrices({ date }),
          await getApi().getDiscountsConfiguration()
        )
        effects.notifyPricingModification()
      }
    },
    savePlanFormula(effects, planFormula) {
      this.state.planFormula = planFormula // Used to display upgradePrice
    },
    async updatePlanFormula(effects) {
      // useless with cart.compute()
      const planFormula = await updatedPlanFormula(
        this.state.order,
        this.state.role,
        this.state.account
      )
      if (
        planFormula &&
        planFormula.tax.tax !== this.state.order[0].planFormula.tax.tax
      ) {
        effects.updateOrder(this.state.order[0].id, {
          planFormula
        })
      }
    },
    updatePayment(effects, data) {
      this.state.payment = { ...this.state.payment, ...data }
      if (data.paymentMethod || data.usePreviousCard) {
        effects.handleChangeOrderSaved(false)
      }
    },
    handleChangeOrderSaved(_, status) {
      this.state.isOrderSaved = status
    },
    async redirectToAccount(effects) {
      try {
        const crossAuthtoken = await generateCrossAuthToken(
          this.state.account.token
        )
        window.location.replace(
          `${
            this.state.config.accountFrontEndUrl
          }/#/purchases?cat=${encodeURIComponent(crossAuthtoken)}`,
          '_blank'
        )
      } catch (error) {
        await effects.handleError(error)
      }
    },
    resetOrder(effects) {
      this.state.order = []
      this.state.payment = {}
      this.state._order = new Order({
        currency: this.state.currency,
        role: this.state.role,
        subCompany: this.state.subCompany
      })
    },
    resetCart() {
      this.state._order = { ...this.state._order, cart: [] }
    },
    resetStore(effects) {
      effects.resetOrder()
      this.state.initialOrder = undefined
      this.state.initialPurchase = undefined
      this.state.orderId = undefined
      this.state.preSelectedProduct = undefined
      this.state.selfbound = this.state.role === 'reseller' ? false : undefined
    },
    logOut() {
      Cookies.remove('accessToken')
      this.state.account = undefined
      this.state.guessedCountry = undefined
      this.state._order = new Order()
      this.state.order = []
      this.state.payment = {}
      this.state.preSelectedProduct = undefined
      this.state.initialPurchase = undefined
      this.state.initialOrder = undefined
      this.state.orderId = undefined
      window.location.replace('/#/login')
    },
    /**
     * @param {string} type: info, success, warning, error, default
     * @param {string} message
     * @param {number || boolean} autoClose
     */
    notify(_, type, message, toastId, autoClose = 10000) {
      toast[type](message, {
        position: 'top-center',
        autoClose: autoClose,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        toastId: toastId
      })
    },
    dismissNotifications() {
      toast.dismiss()
    },
    async handleError(effects, error) {
      await Utils._handleError(error, this.state.customerId, effects.notify)
    },
    async chooseFormula(_, newType) {
      const order = this.state._order
      if (order.isCartLocked) {
        return
      }
      // const {year} = order.paymentModel
      order.paymentModel.type = newType
      order.paymentModel.year = undefined
      const date = order.fromQuote && order.fromQuote.quoteCreationDate
      getCartWrapper(order).compute(
        this.state.country,
        await getApi().getPrices({ date }),
        await getApi().getDiscountsConfiguration()
      )
      // this.state._order = {...this.state._order, paymentModel: {year, type: newType}}
      this.state._order = { ...this.state._order }
    },
    async choosePeriod(_, newPeriod) {
      const order = this.state._order
      if (order.isCartLocked) {
        return
      }
      // const {type} = this.state._order.paymentModel
      order.paymentModel.year = newPeriod
      const date = order.fromQuote && order.fromQuote.quoteCreationDate
      getCartWrapper(order).compute(
        this.state.country,
        await getApi().getPrices({ date }),
        await getApi().getDiscountsConfiguration()
      )
      // this.state._order = {...this.state._order, paymentModel: {type, year: newPeriod}}
      this.state._order = { ...this.state._order }
    },
    async chooseQuantity(_, productId, newQuantity) {
      if (this.state._order.isCartLocked) {
        return
      }
      // this is here as long as we have UNIQUE product carts.
      const product = find(
        this.state._order.cart,
        item => item.type === 'product' && item.productId === productId
      )
      product && (product.quantity = newQuantity)
      const date =
        this.state._order.fromQuote &&
        this.state._order.fromQuote.quoteCreationDate
      getCartWrapper(this.state._order).compute(
        this.state.country,
        await getApi().getPrices({ date }),
        await getApi().getDiscountsConfiguration()
      )
      this.state._order = { ...this.state._order }
    }
  },
  computed: {
    uniqueProduct: ({ _order }) => {
      return (
        (_order && find(_order.cart, item => item.type === 'product')) || {}
      )
    },
    baseUrl: ({ config }) => config && config.baseUrl,
    companyUrl: ({ config }) => config && config.companyUrl,
    country: ({ account, guessedCountry }) =>
      account && account.billingInfo && account.billingInfo.country
        ? account.billingInfo.country
        : guessedCountry,
    email: ({ account }) => account && account.email,
    logged: ({ account }) => Boolean(account),
    role: ({ forceRole, account }) =>
      forceRole
        ? forceRole
        : account &&
          account.role &&
          account.role.reseller &&
          account.role.reseller.status === 'granted'
        ? 'reseller'
        : 'purchaser',
    subCompany: ({ account }) => account && getSubCompany(account),
    xwToken: ({ account }) => account && account.token
  }
})

const App = ({ effects, state }) =>
  state.waiting || state.waitingLogin ? (
    <Loader />
  ) : (
    <Fragment>
      <ToastContainer
        position="top-center"
        autoClose={20000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      <Helmet>
        <title>Vates store</title>
      </Helmet>
      <Header
        config={state.config}
        email={state.email}
        logOut={effects.logOut}
        role={state.role}
      />
      <HashRouter>
        <Switch>
          <PrivateRoute
            path="/purchase"
            component={() => (
              <StripeProvider apiKey={state.config.stripePublicKey}>
                <Elements>
                  <Purchase />
                </Elements>
              </StripeProvider>
            )}
          />
          <PrivateRoute
            path="/manual-order"
            component={() => (
              <StripeProvider apiKey={state.config.stripePublicKey}>
                <Elements>
                  <ManualOrder />
                </Elements>
              </StripeProvider>
            )}
          />
          <PrivateRoute
            path="/extend-purchase"
            component={() => (
              <StripeProvider apiKey={state.config.stripePublicKey}>
                <Elements>
                  <ExtendPurchase />
                </Elements>
              </StripeProvider>
            )}
          />
          <PrivateRoute
            path="/upgrade-order"
            component={() => (
              <StripeProvider apiKey={state.config.stripePublicKey}>
                <Elements>
                  <UpgradeOrder />
                </Elements>
              </StripeProvider>
            )}
          />
          <PrivateRoute
            path="/extend-order"
            component={() => (
              <StripeProvider apiKey={state.config.stripePublicKey}>
                <Elements>
                  <ExtendOrder />
                </Elements>
              </StripeProvider>
            )}
          />
          <PrivateRoute
            path="/new-purchase"
            component={() => (
              <StripeProvider apiKey={state.config.stripePublicKey}>
                <Elements>
                  <NewPurchase />
                </Elements>
              </StripeProvider>
            )}
          />
          <PrivateRoute
            path="/purchase-end"
            component={() => (
              <PurchaseEnd
                config={state.config}
                paymentMethod={state.payment.paymentMethod}
                resetOrder={effects.resetOrder}
                role={state.role}
              />
            )}
          />
          <Route
            path="/login"
            render={() => (
              <AuthenticationForm
                waiting={state.waiting}
                onSubmit={effects.signIn}
              />
            )}
          />
          <Route path="/error" render={() => <ErrorPage />} />
          <Redirect from="*" to="/new-purchase" />
        </Switch>
      </HashRouter>
      <ResellerModal />
      <Footer />
    </Fragment>
  )

export default withState(injectState(App))
