import Currency from 'react-currency-formatter'
import Icon from 'react-icons-kit'
import React, { Fragment } from 'react'
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap'
import { ic_shopping_cart } from 'react-icons-kit/md/ic_shopping_cart'
import { injectState, provideState } from 'reaclette'
import { isEmpty } from 'lodash'

import {
  CHECK,
  STRIPE,
  TRANSFER,
  SUBSCRIPTION,
  PAID_PERIOD
} from '../../purchase-utils'

const withState = provideState({
  computed: {
    planBindMessage: (_, { order = [] }) => {
      if (order.selfbound === true) {
        return 'Buy for my own use'
      }
      if (order.selfbound === false) {
        return 'Buy for another account'
      }
    },
    planDurationMessage: (_, { order }) => {
      if (order.planFormula.type === SUBSCRIPTION) {
        return order.planFormula.year === 0 ? 'per month' : 'per year'
      }
      if (order.planFormula.type === PAID_PERIOD) {
        return `for ${order.planFormula.year} year${
          order.planFormula.year > 1 ? 's' : ''
        }`
      }
    },
    paymentMethodMessage: (_, { payment }) => {
      if (payment.paymentMethod === STRIPE) {
        return 'Credit card'
      }
      if (payment.paymentMethod === TRANSFER) {
        return 'Bank wire transfer'
      }
      if (payment.paymentMethod === CHECK) {
        return 'Bank check'
      }
    }
  }
})

const Summary = ({ state, order = [], billingInfo, currency }) => (
  <Card style={{ backgroundColor: '#f8f8f8' }} className="h-100">
    <CardBody>
      <CardTitle>
        <Icon icon={ic_shopping_cart} size={20} /> Your order
      </CardTitle>
      <Row>
        <Col>
          <span>
            <h5>
              {order.icon && (
                <img alt="logo product" height="30" src={order.icon} />
              )}{' '}
              <span
                style={{
                  color: '#b42626',
                  fontSize: '16px',
                  fontWeight: 'bold'
                }}
              >
                {order.name}
              </span>
            </h5>
          </span>
        </Col>
      </Row>
      {billingInfo.firstName && (
        <Row>
          <Col>
            <h6 className="text-muted">First name</h6>
          </Col>
          <Col>
            <div className="align-right">{billingInfo.firstName}</div>
          </Col>
        </Row>
      )}
      {billingInfo.lastName && (
        <Row>
          <Col>
            <h6 className="text-muted">Last name</h6>
          </Col>
          <Col>
            <div className="align-right">{billingInfo.lastName}</div>
          </Col>
        </Row>
      )}
      {billingInfo.street && (
        <Row>
          <Col>
            <h6 className="text-muted">Address</h6>
          </Col>
          <Col>
            <div className="align-right">{billingInfo.street}</div>
          </Col>
        </Row>
      )}
      {billingInfo.city && (
        <Row>
          <Col>
            <h6 className="text-muted">City</h6>
          </Col>
          <Col>
            <div className="align-right">{billingInfo.city}</div>
          </Col>
        </Row>
      )}
      {billingInfo.state && (
        <Row>
          <Col>
            <h6 className="text-muted">State</h6>
          </Col>
          <Col>
            <div className="align-right">{billingInfo.state}</div>
          </Col>
        </Row>
      )}
      {billingInfo.country && (
        <Row>
          <Col>
            <h6 className="text-muted">Country</h6>
          </Col>
          <Col>
            <div className="align-right">{billingInfo.country}</div>
          </Col>
        </Row>
      )}
      <br />
      {!isEmpty(order.planFormula) && (
        <Fragment>
          <Row>
            <Col>
              <h6 className="text-muted">{order.planFormula.name}</h6>
            </Col>
            <Col>
              <h6 className="align-right">
                {order.quantity > 1 && (
                  <span
                    style={{
                      color: '#b42626',
                      fontSize: '16px',
                      fontWeight: 'bold'
                    }}
                  >
                    {order.quantity}x{' '}
                  </span>
                )}
                <Currency
                  quantity={order.planFormula.amount}
                  currency={currency}
                />
              </h6>
            </Col>
          </Row>
          {!isEmpty(order.planFormula.discount) && (
            <Row>
              <Col>
                <h6 className="text-muted">
                  {order.planFormula.discount.name}
                </h6>
              </Col>
              <Col>
                <h6 className="align-right">
                  <Currency
                    quantity={order.planFormula.discount.amount}
                    currency={currency}
                  />
                </h6>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <h5 className="text-muted">Sub-total</h5>
            </Col>
            {!isEmpty(order.planFormula.tax) && (
              <Col>
                <h6 className="align-right">
                  <Currency
                    quantity={order.planFormula.tax.price * order.quantity}
                    currency={currency}
                  />
                </h6>
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              <h6 className="text-muted">VAT</h6>
            </Col>
            {!isEmpty(order.planFormula.tax) && (
              <Col>
                <h6 className="align-right">
                  <Currency
                    quantity={order.planFormula.tax.tax * order.quantity}
                    currency={currency}
                  />
                </h6>
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              <h5 className="text-muted">TOTAL</h5>
            </Col>
            {!isEmpty(order.planFormula.tax) && (
              <Col>
                <h5 className="align-right">
                  <strong>
                    <Currency
                      quantity={order.planFormula.tax.total * order.quantity}
                      currency={currency}
                    />
                  </strong>
                  &nbsp;
                  <span className="text-muted align-right">
                    {state.planDurationMessage}
                  </span>
                </h5>
              </Col>
            )}
          </Row>
        </Fragment>
      )}
      <br />
      {state.planBindMessage && (
        <Row>
          <Col>
            <span className="text-muted">Buy it for:</span>
          </Col>
          <Col>
            <h6 className="align-right">
              <strong>{state.planBindMessage}</strong>
            </h6>
          </Col>
        </Row>
      )}
      {state.paymentMethodMessage && (
        <Row>
          <Col>
            <span className="text-muted">Payment with</span>
          </Col>
          <Col>
            <h6 className="align-right">
              <strong>{state.paymentMethodMessage}</strong>
            </h6>
          </Col>
        </Row>
      )}
    </CardBody>
  </Card>
)

export default withState(injectState(Summary))
