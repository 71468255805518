import React from 'react'
import { injectState, provideState } from 'reaclette'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

const withState = provideState({
  initialState: () => ({
    resellerModal: false
  }),
  effects: {
    initialize(effects) {
      if (this.state.role === 'reseller' && !this.state.initialOrder) {
        effects.openModal()
      }
    },
    closeModal() {
      this.state.resellerModal = false
    },
    openModal() {
      this.state.resellerModal = true
    },
    redirectToPartnerPortal() {
      window.location.replace(this.state.config.partnerPortalFrontEndUrl)
    }
  }
})

const ResellerModal = ({ effects, state }) => (
  <Modal isOpen={state.resellerModal}>
    <ModalHeader toggle={effects.closeModal}>
      You are a Vates Partner
    </ModalHeader>
    <ModalBody>
      <div style={{ marginBottom: '16px' }}>
        <span style={{ fontWeight: 'bold' }}>
          This form should only be used to pay for existing orders with a credit
          card.
        </span>
      </div>
      <div style={{ marginBottom: '16px' }}>
        As a Vates Partner, please place all your new orders through our{' '}
        <a
          href={state.config.partnerPortalFrontEndUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          Partner Portal
        </a>
        . This ensures that your orders are processed efficiently and that your
        current partner discount is applied.
      </div>
      If you would like to pay for an existing order placed through the Partner
      Portal, please click the relevant button below.
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={effects.redirectToPartnerPortal}>
        Go to the Partner Portal
      </Button>
      <Button color="success" onClick={effects.closeModal}>
        Pay for an existing Order
      </Button>
    </ModalFooter>
  </Modal>
)

export default withState(injectState(ResellerModal))
