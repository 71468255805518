import Icon from 'react-icons-kit'
import React, { Fragment } from 'react'
import { Button, Card, CardText, Col, Row } from 'reactstrap'
import { injectState } from 'reaclette'
import { ic_check_circle } from 'react-icons-kit/md/ic_check_circle'
import { ic_rss_feed } from 'react-icons-kit/md/ic_rss_feed'

import { STRIPE, TRANSFER } from '../../purchase-utils'
import BankInformations from '../../components/bank-informations'

const Recap = ({ effects, state, payment }) => (
  <Fragment>
    {payment.paymentMethod === TRANSFER && (
      <Card body>
        <CardText tag="div">
          <Row>
            <Col>
              <div className="text-center" style={{ fontSize: '25px' }}>
                <h1>Thank you</h1>
                <Icon
                  icon={ic_check_circle}
                  size={50}
                  style={{ verticalAlign: 'middle', color: 'green' }}
                />
                Your purchase request has started.
                <p className="text-center" style={{ fontSize: '25px' }}>
                  <Icon
                    icon={ic_rss_feed}
                    size={50}
                    style={{ verticalAlign: 'middle' }}
                  />
                  Please follow your purchase on this&nbsp;
                  <span className="link" onClick={effects.redirectToAccount}>
                    link
                  </span>
                </p>
              </div>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                You have selected the <strong>Wire transfer</strong> payment
                method.
              </p>
              <p>
                Note that your product will be available <strong>after</strong>{' '}
                the payment is received by our bank.
              </p>
              <p>
                Please, put a clear reference for the wire transfer in order to
                help us easily find your payment.
              </p>
            </Col>
            <Col>
              <p>Here are the banking information to proceed to the payment:</p>
              <Card body>
                <CardText tag={'div'}>
                  <BankInformations />
                </CardText>
              </Card>
            </Col>
          </Row>
        </CardText>
      </Card>
    )}
    {payment.paymentMethod === STRIPE && (
      <Card body>
        <CardText tag="div">
          <Row>
            <Col>
              <div className="text-center" style={{ fontSize: '25px' }}>
                <h1>Thank you</h1>
                <Icon
                  icon={ic_check_circle}
                  size={50}
                  style={{ verticalAlign: 'middle', color: 'green' }}
                />
                Your purchase is completed! You can now enjoy your Xen
                Orchestra.
                <p className="text-center" style={{ fontSize: '25px' }}>
                  <Icon
                    icon={ic_rss_feed}
                    size={50}
                    style={{ verticalAlign: 'middle' }}
                  />
                  You can follow your purchase on this&nbsp;
                  <span className="link" onClick={effects.redirectToAccount}>
                    link
                  </span>
                </p>
              </div>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col
              md="12"
              lg="4"
              style={{ textAlign: 'center', padding: '15px' }}
            >
              <h3>Get started</h3>
              Start using Xen Orchestra in the best possible way with these 10
              advices to get started.
              <div>
                <a
                  className="btn btn-info btn-lg"
                  href={`${state.config.baseUrl}/blog/how-to-start-with-xen-orchestra/`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more
                </a>
              </div>
            </Col>
            <Col
              md="12"
              lg="4"
              style={{ textAlign: 'center', padding: '15px' }}
            >
              <h3>Bind your end-user</h3>
              If you have selected "buy for someone else" during the purchase,
              it's time to bind your end-user account.
              <div>
                <a
                  className="btn btn-info btn-lg"
                  href={`${state.config.baseUrl}/docs/through_purchase_department.html#as-billing-contact`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn how
                </a>
              </div>
            </Col>
            <Col
              md="12"
              lg="4"
              style={{ textAlign: 'center', padding: '15px' }}
            >
              <h3> Manage your account</h3>
              Changing your credit card, getting your invoice, adding an
              additional recipient...
              <div>
                <Button
                  size="lg"
                  color="info"
                  onClick={effects.redirectToAccount}
                >
                  Profile page
                </Button>
              </div>
            </Col>
          </Row>
        </CardText>
      </Card>
    )}
  </Fragment>
)

export default injectState(Recap)
