import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import { injectState, provideState } from 'reaclette'
import { injectStripe } from 'react-stripe-elements'
import * as XwApiErrors from '@vates/xw-api-errors'
import { Order } from '@vates/www-xo-utils'

import BillingInformation from '../billing-information'
import UpgradePlan from './upgrade-plan'
import Recap from '../recap'
import Stepper from '../stepper'
import { getApi } from '../../../api'
import {
  addOrReplaceStripeCard,
  isValidBillingInfo,
  STRIPE,
  TRANSFER,
  validateOrder
} from '../../purchase-utils'
import { makeStepperData } from '../../../utils'
import Payment from '../payment'
import CreditCardRecap from '../payment/credit-card-recap'
import Summary from '../summary'

const UPGRADE_PLAN = 1
const BILLING_INFO = 2
const PAYMENT_METHOD = 3
const CREDIT_CARD_RECAP = 3.1
const RECAP = 4

const STEPS_PROPS_TEMPLATE = {
  [UPGRADE_PLAN]: {
    state: {
      account: '',
      country: '',
      currency: '',
      role: '',
      selfbound: '',
      xwToken: ''
    },
    effects: {}
  },
  [BILLING_INFO]: {
    state: {
      account: '',
      baseUrl: '',
      country: '',
      role: '',
      xwToken: ''
    },
    effects: {
      updateBillingInfo: ''
    }
  },
  [PAYMENT_METHOD]: {
    state: {
      account: '',
      order: '',
      payment: ''
    },
    effects: {
      updatePayment: ''
    }
  },
  [CREDIT_CARD_RECAP]: {
    state: {},
    effects: {}
  },
  [RECAP]: {
    state: {
      account: '',
      order: '',
      payment: ''
    },
    effects: {}
  }
}

const STEPS = [
  {
    stepComponent: UpgradePlan,
    stepIndex: UPGRADE_PLAN,
    stepName: 'Plan details',
    onNextStep: (effects, state) => {
      if (validateOrder(state._order)) {
        effects.goToStep(BILLING_INFO)
      }
    }
  },
  {
    stepComponent: BillingInformation,
    stepIndex: BILLING_INFO,
    stepName: 'Billing information',
    nextText: state => (state._order?.id ? 'Update order' : 'Save order'),
    onNextStep: async (effects, state) => {
      if (isValidBillingInfo(state.account)) {
        try {
          await getApi().updateAccountInfo(
            state.xwToken,
            state.account.billingInfo
          )

          if (state._order.id) {
            if (!state._order.isCartLocked) {
              await getApi(state._order.role).updateUpgradeOrder(
                state.xwToken,
                state._order
              )
            }
          } else {
            const order = await getApi(state._order.role).placeUpgradeOrder(
              state.xwToken,
              state._order
            )

            await effects.refreshAccountInfo() // Refresh data to update first currency set in placeOrder
            effects.setOrderId(order.id)
            effects.refreshOrder(order)
          }

          effects.handleChangeOrderSaved(true)
          effects.goToStep(PAYMENT_METHOD)
        } catch (error) {
          effects.handleError(error)
        }
      }
    },
    onPreviousStep: effects => {
      effects.goToStep(UPGRADE_PLAN)
    }
  },
  {
    stepComponent: Payment,
    stepIndex: PAYMENT_METHOD,
    stepName: 'Payment method',
    nextText: () => 'Confirm',
    onNextStep: async (effects, state) => {
      try {
        if (state.payment.paymentMethod === TRANSFER) {
          await getApi(state._order.role).upgradeOrderWithTransfer(
            state.xwToken,
            state._order.id
          )
          effects.handleChangeOrderSaved(true)
          effects.goToStep(RECAP)
        }

        if (state.payment.paymentMethod === STRIPE) {
          /**
           * If user want to use new card, we add it or replace the existing card
           */
          if (state.payment.usePreviousCard !== true) {
            await effects.createStripeToken(state.account.billingInfo.lastName)
            await addOrReplaceStripeCard(
              state.xwToken,
              state.account,
              state.payment.cardToken
            )
            await effects.refreshAccountInfo()
          }
          effects.goToStep(CREDIT_CARD_RECAP)
        }
      } catch (error) {
        effects.handleError(error)
      }
    },
    onPreviousStep: effects => {
      effects.goToStep(BILLING_INFO)
    }
  },
  {
    stepHidden: true,
    stepComponent: CreditCardRecap,
    stepIndex: CREDIT_CARD_RECAP,
    stepName: 'Payment method',
    nextText: () => 'Pay',
    onNextStep: async (effects, state, props) => {
      try {
        let order = await getApi(state.role).initUpgradeOrderSubscription(
          state.xwToken,
          state._order.id
        )

        // If 3D secure
        if (order.clientSecret) {
          const answer = await props.stripe.handleCardPayment(
            order.clientSecret
          )
          if (answer.error) {
            if (answer.error.code === 'payment_intent_authentication_failure') {
              throw new XwApiErrors.PurchaseAborted(
                'Your payment failed. You may try again, or choose another payment source if the problem persists.'
              )
            } else {
              throw new Error(`${answer.error.code} : ${answer.error.message}`)
            }
          }
        }
        order = await getApi(state.role).confirmUpgradeOrderSubscription(
          state.xwToken,
          order.id
        )
        effects.handleChangeOrderSaved(true)
        effects.goToStep(RECAP)
      } catch (error) {
        effects.handleError(error)
      }
    },
    onPreviousStep: effects => {
      effects.goToStep(PAYMENT_METHOD)
    }
  },
  {
    stepComponent: Recap,
    stepIndex: RECAP,
    stepName: 'Recap',
    nextText: () => 'Go to account',
    onNextStep: async (effects, state) => {
      try {
        effects.redirectToAccount()
      } catch (error) {
        effects.handleError(error)
      }
    },
    onPreviousStep: (effects, state) => {},
    disablePreviousButton: true
  }
]

const getCorrectstep = (account, order) => {
  if (order.status === Order.ORDER_STATUS.PLACED) {
    return PAYMENT_METHOD
  }
  if (
    order.status === Order.ORDER_STATUS.CARD_EXPECTED ||
    order.status === Order.ORDER_STATUS.CARD_RECURRING_PAYMENT_PENDING ||
    order.status === Order.ORDER_STATUS.CARD_ONESHOT_PAYMENT_PENDING
  ) {
    if (account.stripe && account.stripe.card) {
      return CREDIT_CARD_RECAP
    }
    return PAYMENT_METHOD
  }

  return UPGRADE_PLAN
}

const getStep = currentStep =>
  STEPS.find(step => step.stepIndex === currentStep)

const withState = provideState({
  initialState: () => ({
    currentStep: 1,
    waiting: false
  }),
  effects: {
    async initialize(effects) {
      if (!this.state._order.onUpgrade) {
        window.location.replace('/#/new-purchase')
      }
      if (this.state.forceStep) {
        this.state.currentStep = this.state.forceStep
      } else {
        this.state.currentStep = getCorrectstep(
          this.state.account,
          this.state._order
        )
      }
    },
    async previous(effects) {
      this.state.waiting = true
      getStep(this.state.currentStep).onPreviousStep(effects, this.state)
      this.state.waiting = false
    },
    async next(effects) {
      this.state.waiting = true
      await getStep(this.state.currentStep).onNextStep(
        effects,
        this.state,
        this.props
      )
      this.state.waiting = false
    },
    async createStripeToken(effects, lastname) {
      try {
        const { token } = await this.props.stripe.createToken({
          name: lastname
        })
        if (token) {
          effects.updatePayment({
            cardToken: token.id,
            cardLegalInfo: token.card
          })
        }
      } catch (error) {
        effects.handleError(error)
      }
    },
    goToStep(effects, stepNumber) {
      this.state.currentStep = stepNumber
    }
  }
})

const UpgradeOrder = ({ effects, state }) => (
  <Container fluid>
    <Col md={{ size: 10, offset: 1 }}>
      <Row>
        <Col md="9">
          <Stepper
            currentStep={state.currentStep}
            next={effects.next}
            previous={effects.previous}
            props={makeStepperData(state, effects, STEPS_PROPS_TEMPLATE)}
            steps={STEPS}
            waiting={state.waiting}
          />
        </Col>
        <Col md="3">
          <div style={{ marginTop: '8em' }}>
            <Summary billingInfo={state.account.billingInfo} />
          </div>
        </Col>
      </Row>
    </Col>
  </Container>
)

export default injectStripe(withState(injectState(UpgradeOrder)))
