import React, { Fragment } from 'react'
import { Col, Collapse, FormGroup, Input, Label, Row } from 'reactstrap'
import { injectState, provideState } from 'reaclette'
import { isEmpty } from 'lodash'

import BankWireTransferImg from '../../../imgs/logo_offline_bank_transfer_50x28.svg'
import CreditCard from './credit-card'
import PaymentIcon from '../../../imgs/payment-icon.png'
import {
  SUBSCRIPTION,
  PAID_PERIOD,
  TRANSFER,
  STRIPE
} from '../../purchase-utils'

const withState = provideState({
  effects: {
    initialize(effects) {
      effects.updatePayment({ cardToken: undefined, cardLegalInfo: undefined })
    }
  },
  computed: {
    subscription: (_, { order }) =>
      order[0] &&
      !isEmpty(order[0].planFormula) &&
      order[0].planFormula.type === SUBSCRIPTION,
    paidPeriod: (_, { order }) =>
      order[0] &&
      !isEmpty(order[0].planFormula) &&
      order[0].planFormula.type === PAID_PERIOD,
    previousCardInfo: (_, { account }) =>
      account && account.stripe && account.stripe.card
  }
})

const Payment = ({ state, payment, updatePayment }) => (
  <Fragment>
    <Row>
      <Col>
        <h4>Invoice information</h4>
        <hr />
      </Col>
    </Row>
    <Row>
      <Col>
        <FormGroup tag="fieldset">
          {state.subscription && (
            <Fragment>
              <Row>
                <Col>
                  <FormGroup check>
                    <Label check>
                      <Input
                        autoFocus
                        type="radio"
                        name="radio1"
                        checked={payment.paymentMethod === STRIPE}
                        onChange={() =>
                          updatePayment({ paymentMethod: STRIPE })
                        }
                        required
                      />
                      Credit Card
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <img
                    className="float-right"
                    height="58"
                    width="340"
                    src={PaymentIcon}
                    alt="Visa Card"
                  />
                </Col>
              </Row>
              <Collapse isOpen={payment.paymentMethod === STRIPE}>
                {state.previousCardInfo && (
                  <FormGroup tag="card-options">
                    <ul className="list">
                      <li className="list__item">
                        <FormGroup check>
                          <input
                            checked={payment.usePreviousCard === true}
                            className="radio-btn"
                            id={`previous-card-radio-bottom`}
                            name="card-options"
                            onChange={() =>
                              updatePayment({ usePreviousCard: true })
                            }
                            required
                            type="radio"
                          />
                          <label
                            htmlFor={`previous-card-radio-bottom`}
                            className="label"
                          >
                            Use your card:
                            <span className="text-muted text-font-bold">
                              **** **** **** {state.previousCardInfo.last4}
                            </span>
                          </label>
                        </FormGroup>
                      </li>
                      <li className="list__item">
                        <FormGroup check>
                          <input
                            checked={
                              payment.usePreviousCard === false ||
                              !state.previousCardInfo
                            }
                            className="radio-btn"
                            id={`new-card-radio-bottom`}
                            name="card-options"
                            onChange={() =>
                              updatePayment({
                                usePreviousCard: false
                              })
                            }
                            required
                            type="radio"
                          />
                          <label
                            htmlFor={`new-card-radio-bottom`}
                            className="label"
                          >
                            Add new card
                          </label>
                        </FormGroup>
                      </li>
                    </ul>
                  </FormGroup>
                )}
                {(payment.usePreviousCard === false ||
                  !state.previousCardInfo) && <CreditCard />}
              </Collapse>
            </Fragment>
          )}
          {state.paidPeriod && (
            <Fragment>
              <Row>
                <Col>
                  <FormGroup check>
                    <Label check>
                      <Input
                        autoFocus
                        type="radio"
                        name="radio1"
                        checked={payment.paymentMethod === TRANSFER}
                        onChange={() =>
                          updatePayment({ paymentMethod: TRANSFER })
                        }
                        required
                      />
                      Bank Wire Transfer
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <img
                    className="float-right"
                    height="28"
                    width="50"
                    src={BankWireTransferImg}
                    alt="Bank wire Card"
                  />
                </Col>
              </Row>
              <Collapse isOpen={payment.paymentMethod === TRANSFER}>
                <Row>
                  <Col>
                    <br />
                    <p>
                      Your product will be available <strong>after</strong> we
                      receive the funds
                    </p>
                    <p className="text-muted">
                      All the required information to proceed to payment will be
                      displayed in the <strong>confirmation</strong> page
                    </p>
                    <p className="text-muted">
                      You will also find the information needed for payment on
                      your invoice
                    </p>
                  </Col>
                </Row>
              </Collapse>
            </Fragment>
          )}
        </FormGroup>
      </Col>
    </Row>
  </Fragment>
)

export default withState(injectState(Payment))
