import React, { Fragment } from 'react'
import { injectState } from 'reaclette'
import Icon from 'react-icons-kit'
import { ic_account_balance } from 'react-icons-kit/md/ic_account_balance'

const BANK = {
  FR: {
    EUR: {
      name: 'BNP Paribas Arc Alpin Entreprise',
      iban: 'FR76 3000 4024 7500 0107 0271 308',
      bic: 'BNPAFRPPXXX'
    },
    USD: {
      name: 'BNP Paribas Arc Alpin Entreprise',
      iban: 'FR76 3000 4024 7500 0100 5667 174',
      bic: 'BNPAFRPPXXX'
    }
  },
  IT: {
    EUR: {
      name: 'BNP Paribas Arc Alpin Entreprise',
      iban: 'IT18 E010 3002 0010 0000 0924 030'
    },
    USD: {
      name: 'BNP Paribas Arc Alpin Entreprise',
      iban: 'IT18 E010 3002 0010 0000 0924 030'
    }
  }
}

const BankInformations = ({ state }) => {
  const subCompany = state.subCompany || 'FR'
  const currency = state.currency || 'USD'
  const bankInfo = BANK[subCompany][currency]

  return (
    <Fragment>
      <p>
        <strong>Bank Name: </strong> {bankInfo.name}
      </p>
      <p>
        <strong>IBAN:</strong> {bankInfo.iban}
      </p>
      {bankInfo.bic && (
        <p>
          <strong>BIC CODE:</strong> {bankInfo.bic}
          <Icon className="float-right" icon={ic_account_balance} size={32} />
        </p>
      )}
    </Fragment>
  )
}

export default injectState(BankInformations)
