import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import { isEmpty } from 'lodash'
import { injectState, provideState } from 'reaclette'
import { injectStripe } from 'react-stripe-elements'

import BillingInformation from '../billing-information'
import ExtendPlan from './extend-plan'
import Recap from '../recap'
import Stepper from '../stepper'
import { getApi } from '../../../api'
import { isValidBillingInfo, validateOrder } from '../../purchase-utils'
import { makeStepperData } from '../../../utils'
import Summary from '../summary'

const RECAP_PLAN = 1
const BILLING_INFO = 2
const RECAP = 3

const STEPS_PROPS_TEMPLATE = {
  [RECAP_PLAN]: {
    state: {
      account: '',
      country: '',
      currency: '',
      role: '',
      selfbound: '',
      xwToken: ''
    },
    effects: {}
  },
  [BILLING_INFO]: {
    state: {
      account: '',
      baseUrl: '',
      country: '',
      role: '',
      xwToken: ''
    },
    effects: {
      updateBillingInfo: ''
    }
  },
  [RECAP]: {
    state: {
      account: '',
      order: '',
      payment: ''
    },
    effects: {}
  }
}

const STEPS = [
  {
    stepComponent: ExtendPlan,
    stepIndex: RECAP_PLAN,
    stepName: 'Plan details',
    onNextStep: (effects, state) => {
      if (validateOrder(state._order)) {
        effects.goToStep(BILLING_INFO)
      }
    }
  },
  {
    stepComponent: BillingInformation,
    stepIndex: BILLING_INFO,
    stepName: 'Billing information',
    nextText: () => 'Confirm and extend order',
    onNextStep: async (effects, state) => {
      if (isValidBillingInfo(state.account)) {
        try {
          await getApi().updateAccountInfo(
            state.xwToken,
            state.account.billingInfo
          )
          // Extend purchase to order
          const newOrder = await getApi(state.role).extendOrder(
            state.xwToken,
            state.initialOrder.id,
            state._order
          )
          effects.refreshOrder(newOrder)
          effects.setOrderId(newOrder.id)
          effects.handleChangeOrderSaved(true)
          effects.goToStep(RECAP)
        } catch (error) {
          effects.handleError(error)
        }
      }
    },
    onPreviousStep: effects => {
      effects.goToStep(RECAP_PLAN)
    }
  },
  {
    stepComponent: Recap,
    stepIndex: RECAP,
    stepName: 'Recap',
    nextText: () => 'Go to account',
    onNextStep: async (effects, state) => {
      try {
        effects.redirectToAccount()
      } catch (error) {
        effects.handleError(error)
      }
    },
    onPreviousStep: (effects, state) => {},
    disablePreviousButton: true
  }
]

const getStep = currentStep =>
  STEPS.find(step => step.stepIndex === currentStep)

const withState = provideState({
  initialState: () => ({
    currentStep: 1,
    waiting: false
  }),
  effects: {
    async initialize(effects) {
      if (isEmpty(this.state.initialOrder)) {
        window.location.replace('/#/new-purchase')
      }
      if (this.state.forceStep) {
        this.state.currentStep = this.state.forceStep
      }
    },
    async previous(effects) {
      this.state.currentStep = this.state.currentStep - 1
    },
    async next(effects) {
      this.state.waiting = true
      await getStep(this.state.currentStep).onNextStep(
        effects,
        this.state,
        this.props
      )
      this.state.waiting = false
    },
    goToStep(effects, stepNumber) {
      this.state.currentStep = stepNumber
    }
  }
})

const ExtendPurchase = ({ effects, state }) => (
  <Container fluid>
    <Col md={{ size: 10, offset: 1 }}>
      <Row>
        <Col md="8">
          <Stepper
            currentStep={state.currentStep}
            next={effects.next}
            previous={effects.previous}
            props={makeStepperData(state, effects, STEPS_PROPS_TEMPLATE)}
            steps={STEPS}
            waiting={state.waiting}
          />
        </Col>
        <Col md="4">
          <div style={{ marginTop: '8em' }}>
            <Summary billingInfo={state.account.billingInfo} />
          </div>
        </Col>
      </Row>
    </Col>
  </Container>
)

export default injectStripe(withState(injectState(ExtendPurchase)))
