import React, { Fragment } from 'react'
import { Badge, Col, Row } from 'reactstrap'
import { injectState, provideState } from 'reaclette'

import Subscription from './subscription'

const withState = provideState({
  computed: {
    product: ({ order }) => order && order[0]
  }
})

const ExtendPlan = ({ state }) => (
  <Fragment>
    <Row>
      <Col>
        <h4>Product required details</h4>
        <hr />
      </Col>
    </Row>
    <Row>
      <Col>
        <p className="text-muted">Your product</p>
      </Col>
    </Row>
    <h5>
      {state.product && (
        <Fragment>
          {state.product.icon && (
            <img alt="*" height="30" width="30" src={state.product.icon} />
          )}
          <Badge color="light" style={{ fontSize: '16px' }}>
            <span style={{ color: '#b42626' }}>{state.product.name}</span>
          </Badge>
        </Fragment>
      )}
    </h5>
    <Subscription product={state.product} />
    <br />
  </Fragment>
)

export default withState(injectState(ExtendPlan))
