import makeError from 'make-error'

export const HttpClientError = makeError('Http ClientError')
export const HttpNotHandledError = makeError('Http NotHandledError')
export const HttpRedirectionNotHandled = makeError('Http RedirectionNotHandled')
export const HttpServerError = makeError('Http ServerError')

const getCodeRange = code => Math.floor(code / 100)

const request = async (route, body) => {
  const headers = {
    Accept: 'application/json, application/x-nd-json, text/plain, */*',
    'Content-Type': 'application/json;charset=UTF-8'
  }
  if (body instanceof FormData) {
    delete headers['Content-Type']
  }
  const { method } = JSON.parse(body)
  let preventiveError = new Error(`method: ${method}, route: ${route}`)
  let response
  try {
    response = await fetch(route, {
      method: 'POST',
      cache: 'no-cache',
      redirect: 'follow',
      headers,
      body,
      credentials: 'include'
    })
  } catch (error) {
    let _error = new Error(
      `${error.message} ${preventiveError.message} ${preventiveError.stack}`
    )
    throw _error
  }

  if (getCodeRange(response.status) === 1) {
    const error = new HttpNotHandledError(response.statusText)
    error.code = response.status
    throw error
  }
  if (getCodeRange(response.status) === 3) {
    const error = new HttpRedirectionNotHandled(response.statusText)
    error.code = response.status
    throw error
  }
  if (getCodeRange(response.status) === 4) {
    const error = new HttpClientError(response.statusText)
    error.code = response.status
    throw error
  }
  if (getCodeRange(response.status) === 5) {
    const error = new HttpServerError(response.statusText)
    error.code = response.status
    throw error
  }

  // reponse OK
  if (getCodeRange(response.status) === 2) {
    return response
  }
}

export default request
