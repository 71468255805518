import Icon from 'react-icons-kit'
import React, { Fragment } from 'react'
import {
  ButtonGroup,
  Col,
  Input,
  Label,
  Row,
  UncontrolledTooltip
} from 'reactstrap'
import { ic_info_outline } from 'react-icons-kit/md/ic_info_outline'

const BindingChoice = ({ handleSelfbound, role, selfbound }) => (
  <Fragment>
    <Row>
      <Col>
        <p className="text-muted">Choose the plan's end user</p>
      </Col>
    </Row>
    <Row>
      <Col>
        <ButtonGroup>
          {role !== 'reseller' && (
            <Label
              className={`bindingChoice-label btn btn-outline-dark ${
                selfbound ? 'active' : ''
              }`}
            >
              <Input
                required
                type="radio"
                name="options"
                checked={selfbound || ''}
                onChange={() => handleSelfbound(true)}
                className="bindingChoice-input"
              />
              Buy for my own use
            </Label>
          )}
          <Label
            className={`bindingChoice-label btn btn-outline-dark ${
              selfbound === false ? 'active' : ''
            }`}
          >
            <Input
              required
              type="radio"
              name="options"
              checked={selfbound === false || ''}
              onChange={() => handleSelfbound(false)}
              className="bindingChoice-input"
            />
            Buy for another account
          </Label>
        </ButtonGroup>
        {'  '}
        {role !== 'reseller' && (
          <Fragment>
            <Icon
              style={{ verticalAlign: 'middle' }}
              icon={ic_info_outline}
              id="another-account"
              size={20}
            />
            <UncontrolledTooltip placement="right" target="another-account">
              Select "buy for another account" if you want to bind later the
              license to another email address than the one you are using to buy
            </UncontrolledTooltip>
          </Fragment>
        )}
      </Col>
    </Row>
  </Fragment>
)

export default BindingChoice
