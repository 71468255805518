import { request } from './fetch-api'

const cache = {}

export const getDiscountsConfiguration = async () => {
  if (!cache.getDiscountsConfiguration) {
    cache.getDiscountsConfiguration = await request(
      '/api',
      'getDiscountsConfiguration'
    )
  }
  return cache.getDiscountsConfiguration
}

export const getPrices = async ({ date }) => {
  if (!cache.getPrices || !cache.getPrices[date]) {
    !cache.getPrices && (cache.getPrices = {})
    cache.getPrices[date] = await request('/api', 'getPrices', { date })
  }
  return cache.getPrices[date]
}

export const passwordLost = email => request('/api', 'passwordLost', { email })

export const getAccount = token => request('/api', 'getAccount', { token })

export const getClientConf = configurations =>
  request('/api', 'getClientConf', { configurations })

export const authenticate = (email, password) =>
  request('/api', 'authenticate', { email, password })

export const updateAccountInfo = (xwToken, billingInfo) =>
  request('/api', 'updateAccountInfo', { xwToken, billingInfo })

export const verifyVatNumber = (xwToken, countryCode, vatInComNumber) =>
  request('/api', 'verifyVatNumber', { xwToken, countryCode, vatInComNumber })

export const criticalErrorFeedback = (xwToken, error, info) =>
  request('/api', 'criticalErrorFeedback', { xwToken, error, info })

export const addStripeCard = (xwToken, cardToken) =>
  request('/api', 'addStripeCard', { xwToken, cardToken })

export const replaceStripeCard = (xwToken, cardToken) =>
  request('/api', 'replaceStripeCard', { xwToken, cardToken })

export const getPeriodOfSubscription = subscription =>
  request('/api', 'getPeriodOfSubscription', { subscription })
