import Icon from 'react-icons-kit'
import React, { Fragment } from 'react'
import {
  ButtonGroup,
  Col,
  Input,
  Label,
  Row,
  UncontrolledTooltip
} from 'reactstrap'
import { ic_info_outline } from 'react-icons-kit/md/ic_info_outline'
import { injectState, provideState } from 'reaclette'

const withState = provideState({
  effects: {
    async initialize(effects) {
      if (this.props.initialPurchase) {
        const selfbound = Boolean(
          this.props.initialPurchase.user &&
            this.props.initialPurchase.user.customerId === this.props.xwToken
        )
        this.props.updateOrder(this.props.product.id, { selfbound })
      }
    }
  },
  computed: {
    disableSelfbound: (_, { initialPurchase, xwToken }) =>
      !!initialPurchase &&
      (!initialPurchase.user || initialPurchase.user.customerId !== xwToken),
    disableOtherbound: (_, { initialPurchase, xwToken }) =>
      (initialPurchase &&
        initialPurchase.user &&
        initialPurchase.user.customerId === xwToken) ||
      false,
    selfboundClassName: ({ disableSelfbound }, { product }) =>
      `bindingChoice-label btn btn-outline-dark ${
        product.selfbound ? 'active' : ''
      } ${disableSelfbound ? 'disabled' : ''}`,
    otherboundClassName: ({ disableOtherbound }, { product }) =>
      `bindingChoice-label btn btn-outline-dark ${
        product.selfbound === false ? 'active' : ''
      } ${disableOtherbound ? 'disabled' : ''}`
  }
})

const BindingChoice = ({ state, role, product, updateOrder }) => (
  <Fragment>
    <Row>
      <Col>
        <p className="text-muted">Choose the plan's end user</p>
      </Col>
    </Row>
    <Row>
      <Col>
        <ButtonGroup>
          {role !== 'reseller' && (
            <Label className={state.selfboundClassName}>
              <Input
                required
                type="radio"
                name="options"
                id="option1"
                disabled={state.disableSelfbound}
                onChange={() => updateOrder(product.id, { selfbound: true })}
                checked={product.selfbound}
                className="bindingChoice-input"
              />
              Buy for my own use
            </Label>
          )}
          <Label className={state.otherboundClassName}>
            <Input
              required
              type="radio"
              name="options"
              id="option2"
              disabled={state.disableOtherbound}
              checked={role === 'reseller' || product.selfbound === false}
              onChange={() => updateOrder(product.id, { selfbound: false })}
              className="bindingChoice-input"
            />
            Buy for another account
          </Label>
        </ButtonGroup>
        {'  '}
        {role !== 'reseller' && (
          <Fragment>
            <Icon
              style={{ verticalAlign: 'middle' }}
              icon={ic_info_outline}
              id="another-account"
              size={20}
            />
            <UncontrolledTooltip placement="right" target="another-account">
              Select "buy for another account" if you want to bind later the
              license to another email address than the one you are using to buy
            </UncontrolledTooltip>
          </Fragment>
        )}
      </Col>
    </Row>
  </Fragment>
)

export default withState(injectState(BindingChoice))
