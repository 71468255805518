import '../../../style/credit-card.css'
import React, { Fragment } from 'react'
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement
} from 'react-stripe-elements'
import { Col, Input, Row } from 'reactstrap'
import { injectState, provideState } from 'reaclette'

import * as Utils from '../../../utils'

const withState = provideState({
  initialState: () => ({
    cardCvc: undefined,
    cardExpiry: undefined,
    cardNumber: undefined
  }),
  effects: {
    handleChange(_, changeObject) {
      this.state[changeObject.elementType] = changeObject
    }
  },
  computed: {
    validCardNumberInfo: ({ validCardNumber, validExpirationDate, validCVC }) =>
      validCardNumber && validExpirationDate && validCVC,
    validCardNumber: ({ cardNumber }) => Utils.get(() => cardNumber.complete),
    validExpirationDate: ({ cardExpiry }) =>
      Utils.get(() => cardExpiry.complete),
    validCVC: ({ cardCvc }) => Utils.get(() => cardCvc.complete),
    cardNumberErrorMessage: ({ cardNumber }) =>
      Utils.get(() => cardNumber.error.message),
    expirationDateErrorMessage: ({ cardExpiry }) =>
      Utils.get(() => cardExpiry.error.message),
    cvcErrorMessage: ({ cardCvc }) => Utils.get(() => cardCvc.error.message)
  }
})

const CreditCard = ({ state, effects }) => (
  <Fragment>
    <div className="checkout">
      <Row>
        <Col>
          <label>
            Cardholder name
            <Input
              id="card-holder-element"
              className="cardName"
              maxLength="255"
              minLength="2"
              type="text"
              required
              value={state.cardHolderName}
              onChange={effects.handleCardHolderNameChange}
            />
          </label>
        </Col>
      </Row>
      <Row>
        <Col>
          <label>
            Card number
            <CardNumberElement
              id="card-number-element"
              className="cardNumber"
              onChange={effects.handleChange}
            />
          </label>
          <label>
            Expiration date
            <CardExpiryElement
              id="card-expiry-date"
              className="expirationDate"
              onChange={effects.handleChange}
            />
          </label>
          <label>
            CVC
            <CardCVCElement
              id="card-cvc-element"
              className="cvc"
              onChange={effects.handleChange}
            />
          </label>
        </Col>
      </Row>
      <Row
        style={{
          color: 'red'
        }}
      >
        <Col md="4">
          <span>{!state.validCardNumber && state.cardNumberErrorMessage}</span>
        </Col>
        <Col md="2">
          <span>
            {!state.validExpirationDate && state.expirationDateErrorMessage}
          </span>
        </Col>
        <Col md="2">
          <span>{!state.validCVC && state.cvcErrorMessage}</span>
        </Col>
        <Col md="4" />
      </Row>
    </div>
  </Fragment>
)

export default withState(injectState(CreditCard))
