import React from 'react'

import LoadingIcon from '../../imgs/spinner.gif'

const Loader = ({ size = 100 }) => (
  <div style={{ textAlign: 'center' }}>
    <img src={LoadingIcon} alt="Loading..." height={size} width={size} />
  </div>
)

export default Loader
