import Icon from 'react-icons-kit'
import React, { Fragment } from 'react'
import { Card, CardText, Col, Row } from 'reactstrap'
import { ic_account_balance } from 'react-icons-kit/md/ic_account_balance'
import { ic_credit_card } from 'react-icons-kit/md/ic_credit_card'
import { injectState, provideState } from 'reaclette'

import { STRIPE, TRANSFER } from '../../purchase-utils'
import BankInformations from '../../components/bank-informations'

const ConfirmationCreditCard = ({ cardLegalInfo }) => (
  <Card body>
    <CardText tag="div">
      <h3>
        <Icon
          icon={ic_credit_card}
          size={28}
          style={{ verticalAlign: 'middle' }}
        />{' '}
        Your Credit Card{' '}
      </h3>
      <hr />
      <Row>
        <Col>
          <span className="text-muted">Card number</span>
          <span className="float-right">
            {(cardLegalInfo &&
              cardLegalInfo.last4 &&
              `**** **** **** ${cardLegalInfo.last4}`) ||
              '-'}
          </span>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <span className="text-muted">Card holder</span>
          <span className="float-right">
            {(cardLegalInfo && cardLegalInfo.name) || '-'}
          </span>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <span className="text-muted">Card type</span>
          <span className="float-right">
            {(cardLegalInfo && cardLegalInfo.brand) || '-'}
          </span>
        </Col>
      </Row>
    </CardText>
  </Card>
)

const ConfirmationWireTransfer = () => (
  <Card body>
    <CardText tag="div">
      <Row>
        <Col>
          <h3>
            <Icon
              icon={ic_account_balance}
              size={28}
              style={{ verticalAlign: 'middle' }}
            />{' '}
            Wire transfer
          </h3>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            You have selected the <strong>Wire transfer</strong> payment method.
          </p>
          <p>
            Note that your product will be available <strong>after</strong> the
            payment is received by our bank.
          </p>
          <p>
            Please, put a clear reference for the wire transfer in order to help
            us easily find your payment.
          </p>
        </Col>
        <Col>
          <p>Here are the banking information to proceed to the payment:</p>
          <Card body>
            <CardText tag={'div'}>
              <BankInformations />
            </CardText>
          </Card>
        </Col>
      </Row>
    </CardText>
  </Card>
)

const withState = provideState({
  computed: {
    cardLegalInfo: (_, { account, payment }) => {
      if (payment.usePreviousCard) {
        return account.stripe.card
      }
      return payment.cardLegalInfo
    }
  }
})

const Confirmation = ({ state, payment }) => (
  <Fragment>
    {payment.paymentMethod === STRIPE && (
      <ConfirmationCreditCard cardLegalInfo={state.cardLegalInfo} />
    )}
    {payment.paymentMethod === TRANSFER && <ConfirmationWireTransfer />}
  </Fragment>
)

export default withState(injectState(Confirmation))
