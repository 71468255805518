import Icon from 'react-icons-kit'
import { countries, Europe } from '@vates/www-xo-utils'
import debounceDecorator from 'debounce-input-decorator'
import React, { Fragment } from 'react'
import { check } from 'react-icons-kit/fa/check'
import { warning } from 'react-icons-kit/fa/warning'
import { map } from 'lodash'
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  UncontrolledTooltip
} from 'reactstrap'
import { injectState, provideState } from 'reaclette'

import * as Utils from '../../../utils'
import { getApi } from '../../../api'

const InputDebounced = debounceDecorator(350)(Input)

const withState = provideState({
  initialState: ({ account }) => ({
    endUserTerms: false,
    initialBillingInfo: { ...account.billingInfo }
  }),
  effects: {
    initialize(effects) {
      if (
        this.state.billingInfo &&
        !this.state.billingInfo.phone &&
        this.state.billingInfo.country
      ) {
        effects.updateDialCode(this.state.billingInfo.country)
      }
    },
    resetForm(effects) {
      this.props.updateBillingInfo({ ...this.state.initialBillingInfo })
    },
    handleBillingInfoChange(effects, { target }) {
      if (target.name === 'country' && !this.state.billingInfo.phone) {
        effects.updateDialCode(target.value)
      }
      this.props.updateBillingInfo({
        [target.name]:
          target.type === 'checkbox' ? target.checked : target.value
      })
    },
    updateDialCode(_, countryCode) {
      const countryData = countries.find(
        country => country.code === countryCode
      )
      this.props.updateBillingInfo({
        dialCode: countryData
          ? countryData.dialCode
          : this.state.billingInfo.dialCode
      })
    },
    toggleEndUserTerms(effects, { target }) {
      this.state.endUserTerms = target.checked
    },
    async verifyVAT(effects) {
      try {
        const account = await getApi().verifyVatNumber(
          this.props.xwToken,
          this.props.country,
          this.props.account.billingInfo.vatInComNumber
        )
        this.props.updateBillingInfo({ ...account.billingInfo })
        effects.notify('success', 'EU VAT Number verified successfully')
      } catch (error) {
        effects.handleError(error)
      }
    }
  },
  computed: {
    VATNumberIsRequired: (_, { account, country }) =>
      Europe.VATNumberRequired(country, account),
    billingInfo: ({ account }) => account && account.billingInfo
  }
})

const BillingInformation = ({ effects, state, role }) => (
  <Fragment>
    <Row>
      <Col>
        <h4> Billing information </h4>
        <hr />
      </Col>
    </Row>
    <Row>
      <Col md="4">
        <Input
          autoFocus
          name="firstName"
          onChange={effects.handleBillingInfoChange}
          placeholder="First name *"
          required
          type="text"
          value={state.billingInfo.firstName}
        />
      </Col>
      <Col md="4">
        <Input
          name="lastName"
          onChange={effects.handleBillingInfoChange}
          placeholder="Last name *"
          required
          type="text"
          value={state.billingInfo.lastName}
        />
      </Col>
      <Col md="4">
        <Input
          name="company"
          onChange={effects.handleBillingInfoChange}
          placeholder="Company"
          type="text"
          value={state.billingInfo.company}
        />
      </Col>
    </Row>
    <br />
    <Row>
      <Col md="4">
        <Input
          name="position"
          onChange={effects.handleBillingInfoChange}
          placeholder="Position in the company"
          type="text"
          value={state.billingInfo.position}
          required={role === 'reseller'}
        />
      </Col>
      <Col md="4">
        <Input
          name="webSite"
          onChange={effects.handleBillingInfoChange}
          placeholder="Website"
          type="text"
          value={state.billingInfo.webSite}
        />
      </Col>
    </Row>
    <br /> <br />
    <Row>
      <Col>
        <Input
          name="street"
          onChange={effects.handleBillingInfoChange}
          placeholder="address 1 *"
          required
          type="text"
          value={state.billingInfo.street}
        />
      </Col>
      <Col>
        <Input
          name="address2"
          onChange={effects.handleBillingInfoChange}
          placeholder="address 2"
          type="text"
          value={state.billingInfo.address2}
        />
      </Col>
    </Row>
    <br />
    <Row>
      <Col>
        <Input
          name="address3"
          onChange={effects.handleBillingInfoChange}
          placeholder="address 3"
          type="text"
          value={state.billingInfo.address3}
        />
      </Col>
      <Col>
        <Input
          name="address4"
          onChange={effects.handleBillingInfoChange}
          placeholder="address 4"
          type="text"
          value={state.billingInfo.address4}
        />
      </Col>
    </Row>
    <br /> <br />
    <Row>
      <Col>
        <Input
          name="state"
          onChange={effects.handleBillingInfoChange}
          placeholder="State"
          type="text"
          value={state.billingInfo.state}
        />
      </Col>
      <Col>
        <Input
          name="zip"
          onChange={effects.handleBillingInfoChange}
          placeholder="Zip code *"
          required
          type="text"
          value={state.billingInfo.zip}
        />
      </Col>
      <Col>
        <Input
          name="city"
          onChange={effects.handleBillingInfoChange}
          placeholder="City *"
          required
          type="text"
          value={state.billingInfo.city}
        />
      </Col>
    </Row>
    <br />
    <Row>
      <Col>
        <InputDebounced
          name="country"
          onChange={effects.handleBillingInfoChange}
          required
          type="select"
          value={state.billingInfo.country}
        >
          <option value="">country *</option>
          {countries &&
            map(countries, country => (
              <option key={country.name} value={country.code}>
                {country.name}
              </option>
            ))}
        </InputDebounced>
      </Col>
      <Col>
        <InputGroup>
          <Col md="3" style={{ padding: 0 }}>
            <Input
              name="dialCode"
              type="text"
              placeholder="+"
              onChange={effects.handleBillingInfoChange}
              value={state.billingInfo.dialCode}
              required
              style={{ borderRadius: '0.25rem 0 0 0.25rem' }}
            />
          </Col>
          <Input
            name="phone"
            type="text"
            placeholder="Phone *"
            onChange={effects.handleBillingInfoChange}
            value={state.billingInfo.phone}
            required
          />
        </InputGroup>
      </Col>
      {state.VATNumberIsRequired ? (
        <Col>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                {state.billingInfo.country || '-'}
              </InputGroupText>
            </InputGroupAddon>
            <Input
              name="vatInComNumber"
              type="text"
              placeholder="EU VAT Number *"
              onChange={effects.handleBillingInfoChange}
              value={state.billingInfo.vatInComNumber || ''}
              required
            />
            {state.initialBillingInfo.vatInComNumber ===
              state.billingInfo.vatInComNumber && (
              <>
                {state.billingInfo.vatVerified === false && (
                  <Fragment>
                    <InputGroupAddon addonType="append" id="VAT">
                      <Button type="button" onClick={effects.verifyVAT}>
                        <Icon
                          style={{ verticalAlign: 'middle' }}
                          icon={warning}
                          size={18}
                        />{' '}
                        &nbsp;Not verified
                      </Button>
                    </InputGroupAddon>
                    <UncontrolledTooltip placement="top" target="VAT">
                      Your VAT number was not verified
                      <br />
                      due to European Commission
                      <br />
                      Services unavailability.
                      <br />
                      You can click here to retry.
                      <br />
                      An attempt will be made on any
                      <br />
                      future purchase.
                    </UncontrolledTooltip>
                  </Fragment>
                )}
                {state.billingInfo.vatVerified === true && (
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <Icon
                        style={{ verticalAlign: 'middle' }}
                        icon={check}
                        size={18}
                      />
                    </InputGroupText>
                  </InputGroupAddon>
                )}
              </>
            )}
          </InputGroup>
        </Col>
      ) : (
        <Col />
      )}
    </Row>
    <br />
    <FormGroup check>
      <Label check>
        <Input
          type="checkbox"
          name="endUserTerms"
          defaultChecked={state.endUserTerms}
          onChange={effects.toggleEndUserTerms}
          style={{ transform: 'scale(1.5)' }}
          required
        />
        &nbsp; I agree with{' '}
        <a
          href={`${Utils.get(() => state.config.baseUrl)}/#!/legal`}
          target="_blank"
          rel="noopener noreferrer"
          tabIndex="-1"
        >
          Xen Orchestra end user terms and conditions
        </a>
      </Label>
    </FormGroup>
    <br />
    <Button tabIndex="-1" onClick={effects.resetForm}>
      Reset information
    </Button>
  </Fragment>
)

export default withState(injectState(BillingInformation))
