import React, { Fragment } from 'react'
import { Badge, Col, Row } from 'reactstrap'
import { injectState } from 'reaclette'

import Subscription from './subscription'
import { PRODUCTS_ICON, PRODUCTS_NAME } from '../../purchase-utils'

const ExtendPlan = ({ state }) => (
  <Fragment>
    <Row>
      <Col>
        <h4>Product required details</h4>
        <hr />
      </Col>
    </Row>
    <Row>
      <Col>
        <p className="text-muted">Your product</p>
      </Col>
    </Row>
    <h5>
      {state.uniqueProduct && (
        <Fragment>
          {PRODUCTS_ICON[state.uniqueProduct.productId] && (
            <img
              alt="*"
              height="30"
              width="30"
              src={PRODUCTS_ICON[state.uniqueProduct.productId]}
            />
          )}
          <Badge color="light" style={{ fontSize: '16px' }}>
            <span style={{ color: '#b42626' }}>
              {PRODUCTS_NAME[state.uniqueProduct.productId]}
            </span>
          </Badge>
        </Fragment>
      )}
    </h5>
    <Subscription productId={state.uniqueProduct.productId} />
    <br />
  </Fragment>
)

export default injectState(ExtendPlan)
