import { HandledErrors } from '@vates/xw-api-errors'
import { ensurePurchasingStructure, findPurchase, Order } from '@vates/www-xo-utils'
import {
  getPlanFormula,
  getProductsList,
  PAID_PERIOD,
  SUBSCRIPTION
} from './app/purchase-utils'
import { getApi } from './api'
import { find, forEach, pickBy } from 'lodash'

export const generateId = () => 'i' + Math.random().toString(36).slice(2)

export const get = (accessor, arg) => {
  try {
    return accessor(arg)
  } catch (error) {
    if (!(error instanceof TypeError)) {
      // avoid hiding other errors
      throw error
    }
  }
}

export const serializeError = error => ({
  ...error,
  code: error.code,
  message: error.message,
  name: error.name,
  stack: error.stack
})

export const _handleError = async (error, customerId, notify) => {
  const _notify = async (level, message) => {
    if (notify) {
      try {
        await notify(level, message)
      } catch (error) {
        console.log(level, message)
        console.log('NOTIFIER FAILURE', error)
      }
    }
  }
  if (error instanceof HandledErrors) {
    await _notify('error', error.message)
  } else {
    try {
      await getApi().criticalErrorFeedback(
        customerId,
        serializeError(error),
        'STORE'
      )
      await _notify(
        'error',
        "Oops, something went wrong! Don't worry, our team is already on it"
      )
    } catch (feedbackError) {
      await _notify(
        'error',
        'Something went wrong! Contact us if the problem persists'
      )
      console.log('RUNTIME ERROR', error)
      console.log('FEEDBACK API ERROR', feedbackError)
    }
  }
}

export const makeStepperData = (state, effects, template) => {
  const data = {}
  forEach(template, (fields, step) => {
    data[step] = {
      ...pickBy(effects, (_, key) => key in fields.effects),
      ...pickBy(state, (_, key) => key in fields.state)
    }
  })
  return data
}

export const getPurchaseFromNumber = (purchaseNumber, role, account) => {
  if (!purchaseNumber) {
    return undefined
  }
  const roleData = ensurePurchasingStructure(role, account)
  return findPurchase(roleData, purchaseNumber) || undefined
}

export const getProductData = async (productId, role, date) => {
  if (!productId || !role) {
    return
  }

  const productsList = await getProductsList(role, date)
  const product = productsList.find(product => product.id === productId)

  return product
}

export const getPlanFormulaFromOrderId = async (order, role, account, date) => {
  const uniqProduct = Order.getUniqProductFromCart(order.cart)
  const allPlanFormula = await getPlanFormula(
    uniqProduct.productId,
    role,
    account,
    date
  )

  const paymentModel =
    order.paymentModel.type === SUBSCRIPTION ? SUBSCRIPTION : PAID_PERIOD

  const planFormula = find(allPlanFormula[paymentModel], item => item.year === order.paymentModel.year)

  return planFormula
}
