import { trim } from 'lodash'
import React, { Fragment } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader
} from 'reactstrap'
import { injectState, provideState } from 'reaclette'

import { getApi } from '../../api'
import Loader from '../components/loader'
import VatesLogo from '../../imgs/vates-logo.png'

const withState = provideState({
  initialState: () => ({
    email: '',
    password: '',
    modal: false
  }),
  effects: {
    handleChange: (_, { target: { name, value } }) => ({
      [name]: value
    }),
    toggleModal(_effects) {
      this.state.modal = !this.state.modal
    },
    async sendRecoveryMail(effects) {
      try {
        await getApi().passwordLost(this.state.email)
        effects.notify(
          'success',
          'If this email is registered, you will receive a link to reset your password'
        )
        this.state.modal = false
      } catch (error) {
        await effects.handleError(error)
      }
    }
  },
  computed: {
    disabled: state => trim(state.email) === '' || trim(state.password) === ''
  }
})

const AuthentificationForm = ({ effects, state, onSubmit, waiting }) => (
  <div className="sm-center">
    {waiting ? (
      <Loader />
    ) : (
      <Fragment>
        <Card body>
          <CardTitle>
            <div className="text-center">
              <img src={VatesLogo} alt="Vates" width="100" />
              <div
                style={{
                  color: '#333333',
                  fontWeight: '200',
                  fontSize: '24px'
                }}
              >
                Welcome!
              </div>
            </div>
          </CardTitle>
          <CardBody>
            <br />
            <Form
              onSubmit={e => {
                e.preventDefault()
                onSubmit(state.email, state.password)
              }}
            >
              <FormGroup row>
                <Input
                  type="text"
                  name="email"
                  placeholder="Email"
                  value={state.email}
                  onChange={effects.handleChange}
                  required
                />
              </FormGroup>
              <FormGroup row>
                <Input
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={state.password}
                  onChange={effects.handleChange}
                  required
                />
              </FormGroup>
              <FormGroup row>
                <Button
                  color="success"
                  disabled={state.disabled}
                  type="submit"
                  block
                >
                  LOG IN
                </Button>
              </FormGroup>
            </Form>
          </CardBody>
          <div className="align-center">
            <a
              href={`${state.config.accountFrontEndUrl}/#/signup`}
              className="link-primary"
              target="_blank"
              rel="noopener noreferrer"
            >
              No account? Sign Up!
            </a>
            <div>
              <span onClick={effects.toggleModal} className="link-muted">
                Lost activation email or forgot your password?
              </span>
            </div>
          </div>
        </Card>
        <Modal isOpen={state.modal} toggle={effects.toggleModal}>
          <ModalHeader toggle={effects.toggleModal}>
            Account recovery
          </ModalHeader>
          <ModalBody>
            Please give us your email to reset the password of your account.
            <Form id="lost-form">
              <FormGroup>
                <Input
                  type="text"
                  placeholder="Email *"
                  name="email"
                  value={state.email}
                  onChange={effects.handleChange}
                  required
                />
              </FormGroup>
              <Button
                color="success"
                disabled={state.email === ''}
                onClick={effects.sendRecoveryMail}
                block
              >
                Reset password
              </Button>
            </Form>
          </ModalBody>
        </Modal>
      </Fragment>
    )}
  </div>
)

export default withState(injectState(AuthentificationForm))
