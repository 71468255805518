import Icon from 'react-icons-kit'
import React, { Fragment } from 'react'
import { ic_check_circle } from 'react-icons-kit/md/ic_check_circle'
import { ic_rss_feed } from 'react-icons-kit/md/ic_rss_feed'
import { Card, CardText, Col, Row } from 'reactstrap'
import { injectState, provideState } from 'reaclette'

import { STRIPE, TRANSFER } from '../../purchase-utils'

const withState = provideState({
  effects: {
    finalize() {
      this.props.resetOrder()
    }
  }
})

const StripeFinishPage = ({ config, redirectToAccount }) => (
  <Fragment>
    <h2>Thank you</h2>
    <Icon
      icon={ic_check_circle}
      size={50}
      style={{ verticalAlign: 'middle', color: 'green' }}
    />
    <p style={{ marginBottom: '20px' }}>
      Your purchase is completed! You can now enjoy your Xen Orchestra.
    </p>
    <p className="text-center" style={{ fontSize: '25px' }}>
      <Icon icon={ic_rss_feed} size={50} style={{ verticalAlign: 'middle' }} />
      You can follow your purchase on this&nbsp;
      <span className="link" onClick={redirectToAccount}>
        link
      </span>
    </p>
    <Row>
      <Col md="4">
        <h3>Get started</h3>
        Start using Xen Orchestra in the best possible way with these 10 advices
        to get started.
        <div>
          <a
            className="btn btn-warning btn-lg active"
            href={`${config.baseUrl}/blog/how-to-start-with-xen-orchestra/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </a>
        </div>
      </Col>
      <Col md="4">
        <h3>Bind your end-user</h3>
        If you have selected "buy for someone else" during the purchase, it's
        time to bind your end-user account.
        <div>
          <a
            className="btn btn-warning btn-lg active"
            href={`${config.baseUrl}/docs/through_purchase_department.html#as-billing-contact`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn how
          </a>
        </div>
      </Col>
      <Col md="4">
        <h3> Manage your account</h3>
        Changing your credit card, getting your invoice, adding an additional
        recipient...
        <div>
          <a
            className="btn btn-warning btn-lg active"
            href={`${config.accountFrontEndUrl}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Profile page
          </a>
        </div>
      </Col>
    </Row>
  </Fragment>
)

const TranferFinishPage = ({ redirectToAccount }) => (
  <Fragment>
    <h2>Thank you</h2>
    <Icon
      icon={ic_check_circle}
      size={50}
      style={{ verticalAlign: 'middle', color: 'green' }}
    />
    Your purchase request has started.
    <p className="text-center" style={{ fontSize: '25px' }}>
      <Icon icon={ic_rss_feed} size={50} style={{ verticalAlign: 'middle' }} />
      Please follow your purchase on this&nbsp;
      <span className="link" onClick={redirectToAccount}>
        link
      </span>
    </p>
  </Fragment>
)

const PurchaseEnd = ({ effects, config, paymentMethod }) => (
  <div style={{ marginTop: '100px', marginBottom: '100px' }}>
    <div className="xl-center">
      <Card body>
        <CardText tag="div">
          <div className="text-center" style={{ fontSize: '25px' }}>
            {paymentMethod === TRANSFER && (
              <TranferFinishPage
                redirectToAccount={effects.redirectToAccount}
              />
            )}
            {paymentMethod === STRIPE && (
              <StripeFinishPage
                redirectToAccount={effects.redirectToAccount}
                config={config}
              />
            )}
          </div>
        </CardText>
      </Card>
    </div>
  </div>
)

export default withState(injectState(PurchaseEnd))
