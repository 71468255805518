import React from 'react'
import {
  Col,
  Collapse,
  FormGroup,
  Input,
  Label,
  Row,
  Card,
  CardHeader,
  CardBody,
  CardText
} from 'reactstrap'
import { injectState } from 'reaclette'

import BankWireTransferImg from '../../../imgs/logo_offline_bank_transfer_50x28.svg'
import { STRIPE, TRANSFER } from '../../purchase-utils'

const WireTransfer = ({ state, effects }) => (
  <Card>
    <CardHeader>
      <Row>
        <Col>
          <FormGroup check>
            <Label check>
              <Input
                tabIndex={state.payment.paymentMethod === STRIPE ? '-1' : '0'}
                type="radio"
                name="radio-method"
                checked={state.payment.paymentMethod === TRANSFER}
                onChange={() =>
                  effects.updatePayment({ paymentMethod: TRANSFER })
                }
                required
              />
              Bank Wire Transfer
            </Label>
          </FormGroup>
        </Col>
        <Col>
          <img
            className="float-right"
            height="28"
            width="50"
            src={BankWireTransferImg}
            alt="Bank wire Card"
          />
        </Col>
      </Row>
    </CardHeader>
    <Collapse isOpen={state.payment.paymentMethod === TRANSFER}>
      <CardBody>
        <CardText tag="div">
          <Row>
            <Col>
              <br />
              <p>
                Your product will be available <strong>after</strong> we receive
                the funds
              </p>
              <p className="text-muted">
                All the required information to proceed to payment will be
                displayed in the <strong>confirmation</strong> page
              </p>
              <p className="text-muted">
                You will also find the information needed for payment on your
                invoice
              </p>
            </Col>
          </Row>
        </CardText>
      </CardBody>
    </Collapse>
  </Card>
)

export default injectState(WireTransfer)
