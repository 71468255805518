import * as Utils from '@vates/www-xo-utils'
import { HandledErrors } from '@vates/xw-api-errors'
import { includes, isEmpty } from 'lodash'
import { getCartWrapper, Due } from '@vates/sales-lib'

import { getApi } from '../api'
import XcpNgIcon from '../imgs/xcp-ng-icon.png'
import XoIcon from '../imgs/xo-icon.png'

export const PRODUCTS_ICON = {
  enterprise: XoIcon,
  premium: XoIcon,
  starter: XoIcon,
  xosan: XoIcon,
  'xcpng-enterprise': XcpNgIcon,
  'xcpng-standard': XcpNgIcon
}
export const PRODUCTS_NAME = {
  enterprise: 'Xen Orchestra Enterprise Edition',
  premium: 'Xen Orchestra Premium Edition',
  starter: 'Xen Orchestra Starter Edition',
  xosan: 'XOSAN',
  'xcpng-enterprise': 'XCP-NG Enterprise',
  'xcpng-standard': 'XCP-NG standard',
  'xo-proxy': 'XO Proxy'
}
export const CHECK = 'check'
export const STRIPE = 'stripe'
export const TRANSFER = 'transfer'

export const PAID_PERIOD = 'paidPeriod'
export const SUBSCRIPTION = 'subscription'

export const HAS_DISCOUNT_YEARLY = productId =>
  !['xo-proxy', 'xosan', 'xcpng-standard', 'xcpng-enterprise'].includes(
    productId
  )

const mergeDue = due => {
  const base = { ...due.base[0] }
  const allDiscounts = {}
  due.discount.forEach(discount => {
    allDiscounts.amount = allDiscounts.amount
      ? (allDiscounts.amount += discount.amount)
      : discount.amount
    allDiscounts.name = allDiscounts.name
      ? `${allDiscounts.name}, ${discount.name}`
      : discount.name
  })
  const allTaxes = {}
  due.tax.forEach(tax => {
    allTaxes.price = allTaxes.price ? (allTaxes.price += tax.price) : tax.price
    allTaxes.total = allTaxes.total ? (allTaxes.total += tax.total) : tax.total
    allTaxes.amount = allTaxes.amount
      ? (allTaxes.amount += tax.amount)
      : tax.amount
    allTaxes.tax = allTaxes.tax ? (allTaxes.tax += tax.amount) : tax.amount
    allTaxes.name = allTaxes.name ? `${allTaxes.name}, ${tax.name}` : tax.name
  })

  return { ...base, tax: allTaxes, discount: allDiscounts }
}

const productsConfig = {
  free: {
    [SUBSCRIPTION]: {
      monthly: false,
      yearly: false,
      multiple: false
    },
    [PAID_PERIOD]: {
      year: [],
      multiple: false
    }
  },
  starter: {
    [SUBSCRIPTION]: {
      monthly: true,
      yearly: true,
      multiple: false
    },
    [PAID_PERIOD]: {
      year: [1, 2, 3],
      multiple: false
    },
    default: SUBSCRIPTION
  },
  enterprise: {
    [SUBSCRIPTION]: {
      monthly: true,
      yearly: true,
      multiple: false
    },
    [PAID_PERIOD]: {
      year: [1, 2, 3],
      multiple: false
    },
    default: SUBSCRIPTION
  },
  'sb-premium': {
    [SUBSCRIPTION]: {
      monthly: false,
      yearly: false,
      multiple: false
    },
    [PAID_PERIOD]: {
      year: [],
      multiple: false
    },
    default: SUBSCRIPTION
  },
  premium: {
    [SUBSCRIPTION]: {
      monthly: true,
      yearly: true,
      multiple: false
    },
    [PAID_PERIOD]: {
      year: [1, 2, 3],
      multiple: false
    },
    default: SUBSCRIPTION
  },
  xosan: {
    [SUBSCRIPTION]: {
      monthly: false,
      yearly: false,
      multiple: false
    },
    [PAID_PERIOD]: {
      year: [],
      multiple: false
    },
    default: SUBSCRIPTION
  },
  'xcpng-enterprise': {
    [SUBSCRIPTION]: {
      monthly: false,
      yearly: true,
      multiple: true
    },
    [PAID_PERIOD]: {
      year: [1, 2, 3],
      multiple: true
    },
    default: PAID_PERIOD
  },
  'xcpng-standard': {
    [SUBSCRIPTION]: {
      monthly: false,
      yearly: true,
      multiple: true
    },
    [PAID_PERIOD]: {
      year: [1, 2, 3],
      multiple: true
    },
    default: PAID_PERIOD
  },
  'xo-proxy': {
    [SUBSCRIPTION]: {
      monthly: true,
      yearly: true,
      multiple: true
    },
    [PAID_PERIOD]: {
      year: [1, 2, 3],
      multiple: true
    },
    resale: true,
    default: PAID_PERIOD
  },
  xostor: {
    [SUBSCRIPTION]: {
      monthly: false,
      yearly: false,
      multiple: false
    },
    [PAID_PERIOD]: {
      year: [],
      multiple: false
    },
    default: SUBSCRIPTION
  }
}

export const getProductsConfig = () => productsConfig

export const getProductsList = async (role, date) => {
  const products = []
  const productsConfig = getProductsConfig()
  const pricesList = await getApi().getPrices({ date })

  for (const productId in pricesList) {
    if (productsConfig[productId]) {
      const { [SUBSCRIPTION]: subscription, [PAID_PERIOD]: prepaid } =
        productsConfig[productId]
      const icon = PRODUCTS_ICON[productId]
      const monthlyPrice = Utils.currentItemSaleData(pricesList, productId, 0)
      const yearlyPrice = Utils.currentItemSaleData(pricesList, productId, 1)

      const product = {
        icon,
        id: productId,
        productId,
        isBuyable:
          subscription.monthly || subscription.yearly || !isEmpty(prepaid.year),
        isBuyableByQuantityWithSubscription: subscription.multiple,
        isBuyableByQuantityWithPrepaid: prepaid.multiple,
        montly: subscription.monthly && monthlyPrice,
        name: pricesList[productId].name,
        planFormula: {},
        quantity: 1,
        selfbound: role === 'reseller' ? false : undefined, // so far this is not "product" dependent.
        yearly: yearlyPrice
      }

      products.push(product)
    }
  }

  return products
}

export const getPlanFormula = async (productId, role, account, date) => {
  const formulaSubscription = []
  const formulaPrepaid = []
  const productsConfig = getProductsConfig()
  const {
    [SUBSCRIPTION]: subscription,
    [PAID_PERIOD]: prepaid,
    default: defaultChoice
  } = productsConfig[productId]
  const pricesList = await getApi().getPrices({ date })
  const discountConfiguration = await getApi().getDiscountsConfiguration()

  if (subscription && subscription.monthly) {
    const due = Due.determine(
      pricesList,
      discountConfiguration,
      productId,
      0,
      role,
      account
    )
    const mergedDue = mergeDue(due)
    formulaSubscription.push(mergedDue)
  }
  if (subscription.yearly) {
    const due = Due.determine(
      pricesList,
      discountConfiguration,
      productId,
      1,
      role,
      account
    )
    const mergedDue = mergeDue(due)
    formulaSubscription.push(mergedDue)
  }

  if (prepaid.year) {
    prepaid.year.forEach(year => {
      const due = Due.determine(
        pricesList,
        discountConfiguration,
        productId,
        year,
        role,
        account
      )
      const mergedDue = mergeDue(due)
      formulaPrepaid.push(mergedDue)
    })
  }

  return {
    [SUBSCRIPTION]: formulaSubscription,
    [PAID_PERIOD]: formulaPrepaid,
    defaultChoice
  }
}

export const getDefaultFormula = (product, formulaType = SUBSCRIPTION) => {
  const defaultFormula = {}
  if (formulaType === SUBSCRIPTION && !isEmpty(product[SUBSCRIPTION])) {
    defaultFormula.subscription = product.subscription[0]
  } else {
    defaultFormula[PAID_PERIOD] = product[PAID_PERIOD][0]
  }
  return defaultFormula
}

export const updatedPlanFormula = async (order, role, account) => {
  // useless with Cart.compute
  const planFormula = await getPlanFormula(order[0].id, role, account)

  if (order[0].planFormula.type === SUBSCRIPTION) {
    return {
      type: SUBSCRIPTION,
      ...planFormula[SUBSCRIPTION][order[0].planFormula.year]
    }
  }
  if (order[0].planFormula.type === PAID_PERIOD) {
    return {
      type: PAID_PERIOD,
      ...planFormula[PAID_PERIOD][order[0].planFormula.year - 1]
    }
  }
  return null
}

function validatePaymentModel(order, item) {
  const K = {
    0: 'monthly',
    1: 'yearly'
  }

  if (order.paymentModel.type === SUBSCRIPTION) {
    return productsConfig[item.productId][SUBSCRIPTION][
      K[order.paymentModel.year]
    ]
  }
  if (order.paymentModel.type === PAID_PERIOD) {
    return includes(
      productsConfig[item.productId][PAID_PERIOD].year,
      order.paymentModel.year
    )
  }
  return false
}

function validateQuantity(order, item) {
  return (
    item.quantity > 0 &&
    (item.quantity === 1 ||
      productsConfig[item.productId][order.paymentModel.type].multiple)
  )
}

export function validateOrder(order) {
  const cart = getCartWrapper(order)
  const products = cart.products
  if (products.length === 0) {
    return false
  }
  let valid = true
  for (const item of products) {
    valid =
      valid &&
      validatePaymentModel(order, item) &&
      validateQuantity(order, item)
    if (!valid) break
  }
  return valid
}

export const isValidOrder = orders => {
  if (isEmpty(orders)) {
    return false
  }

  const isValid = order =>
    order &&
    order.isBuyable && // isBuyable(order)
    !isEmpty(order.id) &&
    !isEmpty(order.planFormula) &&
    order.quantity >= 1 &&
    typeof order.selfbound === 'boolean'

  return orders.every(isValid)
}

export const isValidNewOrder = orders => {
  // TODO
  if (isEmpty(orders)) {
    return false
  }

  const isValid = order =>
    order &&
    order.isBuyable &&
    !isEmpty(order.id) &&
    !isEmpty(order.planFormula) &&
    order.quantity >= 1

  return orders.every(isValid)
}

export const isValidBillingInfo = account => {
  // @vates/www-xo-utils
  const { billingInfo } = account
  const vatNumber = Utils.Europe.VATNumberRequired(billingInfo.country, account)
    ? billingInfo.vatInComNumber !== ''
    : true

  return (
    vatNumber &&
    billingInfo.city !== '' &&
    billingInfo.country !== '' &&
    billingInfo.firstName !== '' &&
    billingInfo.lastName !== '' &&
    billingInfo.street !== '' &&
    billingInfo.zip !== '' &&
    billingInfo.phone !== ''
  )
}

export const isValidPayment = (account, payment) =>
  (payment.paymentMethod === STRIPE &&
    !payment.usePreviousCard &&
    payment.cardToken) ||
  (payment.paymentMethod === STRIPE &&
    payment.usePreviousCard &&
    hasCreditCard(account)) ||
  payment.paymentMethod === TRANSFER

export const paymentAction = async (
  xwToken,
  role,
  billingInfo,
  currency,
  order,
  payment,
  initialPurchase
) => {
  const {
    id: plan,
    selfbound,
    quantity,
    planFormula: { year }
  } = order
  billingInfo.currency = currency

  if (payment.paymentMethod === STRIPE) {
    if (initialPurchase) {
      return getApi(role).upgradeStripe(
        xwToken,
        plan,
        undefined,
        billingInfo,
        year,
        initialPurchase.number
      )
    }
    return getApi(role).purchaseStripe(
      xwToken,
      plan,
      undefined,
      billingInfo,
      year,
      selfbound
    )
  }

  if (payment.paymentMethod === TRANSFER) {
    if (initialPurchase) {
      return getApi(role).upgradeTransfer(
        xwToken,
        initialPurchase.number,
        plan,
        quantity,
        billingInfo,
        year
      )
    }
    return getApi(role).purchaseTransfer(
      xwToken,
      plan,
      quantity,
      billingInfo,
      year,
      selfbound
    )
  }

  throw new Error('UNHANDLED/UNKNOWN PAYMENT METHOD')
}

export const cancelPayment = async (
  role,
  xwToken,
  purchaseNumber,
  errorMessage
) =>
  getApi(role).cancelPurchase(xwToken, purchaseNumber, 'SYSTEM', errorMessage)

export const hasCreditCard = account =>
  account && account.stripe && !isEmpty(account.stripe.card)

export const addOrReplaceStripeCard = (xwToken, account, cardToken) => {
  if (!cardToken) {
    throw new HandledErrors('Invalid Card')
  }
  if (hasCreditCard(account)) {
    return getApi().replaceStripeCard(xwToken, cardToken)
  } else {
    return getApi().addStripeCard(xwToken, cardToken)
  }
}

export const computeSubTotal = (
  order // useless wuth Cart.compute
) =>
  order.reduce(
    (subTotal, item) =>
      item.planFormula.tax
        ? subTotal + item.planFormula.tax.price * item.quantity
        : subTotal,
    0
  )
export const computeVat = (
  order // useless wuth Cart.compute
) =>
  order.reduce(
    (totalVat, item) =>
      item.planFormula.tax
        ? totalVat + item.planFormula.tax.tax * item.quantity
        : totalVat,
    0
  )
export const computeTotal = (
  order // useless wuth Cart.compute
) =>
  order.reduce(
    (total, item) =>
      item.planFormula.tax
        ? total + item.planFormula.tax.total * item.quantity
        : total,
    0
  )

/**
 * Open pop-up with 3D secure authentication
 * @param {function} handleCardPayment
 * @param {string} clientSecret
 */
export const stripeSecureAuthentication = async (
  handleCardPayment,
  clientSecret
) => {
  if (!clientSecret) {
    throw new HandledErrors('Impossible to confirm payment.')
  }
  const answer = await handleCardPayment(clientSecret)
  if (answer.error) {
    if (answer.error.code === 'payment_intent_authentication_failure') {
      throw new HandledErrors(
        'Your payment failed. You may try again, or choose another payment source if the problem persists.'
      )
    } else {
      throw new Error(`${answer.error.code} : ${answer.error.message}`)
    }
  }

  return answer
}

export function isBuyable(productId) {
  return (
    productsConfig[productId][SUBSCRIPTION].montly ||
    productsConfig[productId][SUBSCRIPTION].yearly ||
    !isEmpty(productsConfig[productId][PAID_PERIOD].year)
  )
}

export const getProductName = productId => PRODUCTS_NAME[productId] || productId
