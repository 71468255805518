import '../../../style/radio-buttons.css'
import Currency from 'react-currency-formatter'
import React, { Fragment } from 'react'
import {
  Button,
  ButtonGroup,
  Col,
  Collapse,
  Input,
  Row,
  UncontrolledTooltip
} from 'reactstrap'
import { isEmpty } from 'lodash'
import { injectState, provideState } from 'reaclette'

import {
  HAS_DISCOUNT_YEARLY,
  PAID_PERIOD,
  STRIPE,
  SUBSCRIPTION,
  TRANSFER
} from '../../purchase-utils'
import { generateId } from '../../../utils'
import { getPlanFormula } from '../../purchase-utils'

const withState = provideState({
  initialState: () => ({
    planFormula: {
      [SUBSCRIPTION]: [],
      [PAID_PERIOD]: []
    },
    activeFormula: undefined
  }),
  effects: {
    async initialize(effects) {
      await effects.getPlanFormula()
    },
    async getPlanFormula(effects) {
      try {
        const planFormula = await getPlanFormula(
          this.props.product.id,
          this.props.role,
          this.props.account
        )
        this.state.planFormula = planFormula

        // If the customer has already chosen his plan
        if (!isEmpty(this.props.product.planFormula)) {
          this.state.activeFormula = this.props.product.planFormula.type
        } else {
          // If it's an upgrade, we don't provide to change the payment method (we keep the same payment method as the initial purchase)
          this.props.initialPurchase
            ? (this.state.activeFormula =
                this.props.initialPurchase.source &&
                this.props.initialPurchase.source.method === STRIPE
                  ? SUBSCRIPTION
                  : PAID_PERIOD)
            : (this.state.activeFormula = isEmpty(planFormula.subscription)
                ? PAID_PERIOD
                : SUBSCRIPTION)
        }
      } catch (error) {
        effects.handleError(error)
      }
    },
    toogleActiveFormula(_, typeOfFormula) {
      this.state.activeFormula = typeOfFormula
      // Reset planFormula and quantity if user change the type of formula
      this.props.updateOrder(this.props.product.id, {
        planFormula: {},
        quantity: 1
      })
    }
  },
  computed: {
    disableSubscription: ({ planFormula }, { initialPurchase }) =>
      isEmpty(planFormula[SUBSCRIPTION]) ||
      (initialPurchase && initialPurchase.source.method === TRANSFER),
    disablePaidPeriod: ({ planFormula }, { initialPurchase }) =>
      isEmpty(planFormula[PAID_PERIOD]) ||
      (initialPurchase && initialPurchase.source.method === STRIPE),
    showQuantityInput: ({ activeFormula }, { product }) =>
      (activeFormula === SUBSCRIPTION &&
        product.isBuyableByQuantityWithSubscription) ||
      (activeFormula === PAID_PERIOD && product.isBuyableByQuantityWithPrepaid)
  }
})

const Subscription = ({ effects, state, product, updateOrder, currency }) => (
  <Fragment>
    <Row>
      <Col>
        <p className="text-muted">Choose your plan</p>
      </Col>
    </Row>
    <Row>
      <Col>
        <ButtonGroup>
          <Button
            active={state.activeFormula === SUBSCRIPTION}
            color="dark"
            id="commitment-tooltip-id"
            onClick={() => effects.toogleActiveFormula(SUBSCRIPTION)}
            outline
            style={{ width: '200px' }}
            disabled={state.disableSubscription}
          >
            Subscription
          </Button>
          <Button
            active={state.activeFormula === PAID_PERIOD}
            color="dark"
            onClick={() => effects.toogleActiveFormula(PAID_PERIOD)}
            outline
            style={{ width: '200px' }}
            disabled={state.disablePaidPeriod}
          >
            Paid period
          </Button>
        </ButtonGroup>
        <UncontrolledTooltip placement="top" target="commitment-tooltip-id">
          There is no commitment in Xen Orchestra, you can cancel the renewal at
          any time
        </UncontrolledTooltip>
      </Col>
    </Row>
    <br />
    <Collapse isOpen={state.activeFormula === SUBSCRIPTION}>
      <ul className="list" style={{ listStyle: 'none' }}>
        {state.planFormula &&
          state.planFormula.subscription.map((subscription, index) => (
            <li className="list__item" key={index}>
              <input
                required={state.activeFormula === SUBSCRIPTION}
                type="radio"
                className="radio-btn"
                name={`${product.id}-sub-choice`}
                id={`${product.id}-${index}-sub-btn`}
                onChange={() =>
                  updateOrder(product.id, {
                    planFormula: { type: SUBSCRIPTION, ...subscription }
                  })
                }
                checked={
                  (product.planFormula.type === SUBSCRIPTION &&
                    product.planFormula.year === subscription.year) ||
                  false
                }
              />
              <label
                htmlFor={`${product.id}-${index}-sub-btn`}
                className="label"
              >
                <span className="badge badge-dark" style={{ fontSize: '16px' }}>
                  <Currency
                    quantity={subscription.tax.total}
                    currency={currency}
                  />
                </span>
                {subscription.year > 0 && HAS_DISCOUNT_YEARLY(product.id) && (
                  <span
                    className="badge badge-warning"
                    style={{ fontSize: '14px' }}
                  >
                    SAVE 10%
                  </span>
                )}
                &nbsp;
                <span className="text-muted">
                  per {subscription.year === 0 ? 'month' : 'year'}
                </span>
              </label>
            </li>
          ))}
      </ul>
    </Collapse>
    <Collapse
      isOpen={state.activeFormula === PAID_PERIOD}
      style={{ marginLeft: '200px' }}
    >
      <ul className="list" style={{ listStyle: 'none' }} required>
        {state.planFormula &&
          state.planFormula[PAID_PERIOD].map((prepaid, index) => (
            <li className="list__item" key={generateId()}>
              <input
                required={state.activeFormula === PAID_PERIOD}
                type="radio"
                className="radio-btn"
                name={`${product.id}-prepaid-choice`}
                id={`${product.id}-${index}-prepaid-btn`}
                checked={
                  (product.planFormula.type === PAID_PERIOD &&
                    product.planFormula.year === prepaid.year) ||
                  false
                }
                onChange={() =>
                  updateOrder(product.id, {
                    planFormula: { type: PAID_PERIOD, ...prepaid }
                  })
                }
              />
              <label
                htmlFor={`${product.id}-${index}-prepaid-btn`}
                className="label"
              >
                {prepaid.year} year{prepaid.year > 1 && 's'} &nbsp; &nbsp;
                <span className="badge badge-dark" style={{ fontSize: '16px' }}>
                  <Currency quantity={prepaid.tax.total} currency={currency} />
                </span>
                {!isEmpty(prepaid.discount) && (
                  <span
                    className="badge badge-warning"
                    style={{ fontSize: '14px' }}
                  >
                    SAVE{' '}
                    <Currency
                      quantity={prepaid.discount.amount * -1}
                      currency={currency}
                    />
                  </span>
                )}
              </label>
            </li>
          ))}
      </ul>
    </Collapse>

    {state.showQuantityInput && (
      <Fragment>
        <Row>
          <Col>
            <p className="text-muted">Quantity</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              min="1"
              onChange={e =>
                updateOrder(product.id, {
                  quantity: e.target.value
                })
              }
              required
              style={{ width: '100px' }}
              type="number"
              value={product.quantity}
            />
          </Col>
        </Row>
      </Fragment>
    )}
  </Fragment>
)

export default withState(injectState(Subscription))
