import '../../../style/radio-buttons.css'
import Currency from 'react-currency-formatter'
import React, { Fragment } from 'react'
import { Button, ButtonGroup, Col, Row } from 'reactstrap'
import { isEmpty } from 'lodash'
import { injectState, provideState } from 'reaclette'

import {
  SUBSCRIPTION,
  PAID_PERIOD,
  getPlanFormula,
  TRANSFER
} from '../../purchase-utils'

const withState = provideState({
  initialState: () => ({
    planFormula: {
      [SUBSCRIPTION]: [],
      [PAID_PERIOD]: []
    }
  }),
  effects: {
    async initialize(effects) {
      await effects.getPlanFormula()
      await effects.initializePaymentModel()
    },
    async getPlanFormula(effects) {
      try {
        if (this.props.product) {
          const planFormula = await getPlanFormula(
            this.props.product.id,
            this.state.role,
            this.state.account
          )
          this.state.planFormula = planFormula
          effects.updatePayment({ paymentMethod: TRANSFER })
        }
      } catch (error) {
        effects.handleError(error)
      }
    },
    async initializePaymentModel(effects) {
      if (!this.state._order.paymentModel.type) {
        if (this.state.initialPurchase) {
          effects.chooseFormula(PAID_PERIOD)
        }
      }
    }
  }
})

const Subscription = ({ effects, state, product }) => (
  <Fragment>
    <Row>
      <Col>
        <p className="text-muted">Choose your plan</p>
      </Col>
    </Row>
    <Row>
      <Col>
        <ButtonGroup>
          <Button
            color="dark"
            id="commitment-tooltip-id"
            outline
            style={{ width: '200px' }}
            disabled
          >
            Subscription
          </Button>
          <Button
            type="button"
            active
            color="dark"
            outline
            style={{ width: '200px' }}
          >
            Paid period
          </Button>
        </ButtonGroup>
      </Col>
    </Row>
    <br />
    <div style={{ marginLeft: '200px' }}>
      <ul className="list" style={{ listStyle: 'none' }} required>
        {state.planFormula &&
          state.planFormula[PAID_PERIOD].map((prepaid, index) => (
            <li className="list__item" key={index}>
              <input
                required
                type="radio"
                className="radio-btn"
                name={`${product.id}-prepaid-choice`}
                id={`${product.id}-${index}-prepaid-btn`}
                checked={product.planFormula.year === prepaid.year || false}
                onChange={() => {
                  effects.updateOrder(product.id, {
                    planFormula: { type: PAID_PERIOD, ...prepaid }
                  })
                  effects.choosePeriod(prepaid.year)
                }}
              />
              <label
                htmlFor={`${product.id}-${index}-prepaid-btn`}
                className="label"
              >
                {prepaid.year} year{prepaid.year > 1 && 's'} &nbsp; &nbsp;
                <span className="badge badge-dark" style={{ fontSize: '16px' }}>
                  <Currency
                    quantity={prepaid.tax.total}
                    currency={state.currency}
                  />
                </span>
                {!isEmpty(prepaid.discount) && (
                  <span
                    className="badge badge-warning"
                    style={{ fontSize: '14px' }}
                  >
                    SAVE{' '}
                    <Currency
                      quantity={prepaid.discount.amount * -1}
                      currency={state.currency}
                    />
                  </span>
                )}
              </label>
            </li>
          ))}
      </ul>
    </div>
  </Fragment>
)

export default withState(injectState(Subscription))
