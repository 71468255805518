import * as Utils from '@vates/www-xo-utils'
import Currency from 'react-currency-formatter'
import Icon from 'react-icons-kit'
import React, { Fragment } from 'react'
import { Badge, Card, CardBody, CardTitle, Col, Row } from 'reactstrap'
import { ic_shopping_cart } from 'react-icons-kit/md/ic_shopping_cart'
import { injectState, provideState } from 'reaclette'
import { PRODUCTS_NAME } from '../purchase-utils'

const withState = provideState({
  computed: {
    cartItemTotal: state =>
      state.initialOrder &&
      state.initialOrder.cart &&
      state.initialOrder.cart.find(cartItem => cartItem.type === 'total'),
    amountExclTax: state => state.cartItemTotal && state.cartItemTotal.amount,
    amountTax: state =>
      state.cartItemTotal &&
      state.cartItemTotal.tax &&
      state.cartItemTotal.tax.amount,
    total: state =>
      state.amountTax
        ? +state.amountExclTax + +state.amountTax
        : +state.amountExclTax,
    cartItems: state =>
      state.initialOrder &&
      state.initialOrder.cart &&
      state.initialOrder.cart.filter(cartItem => cartItem.type === 'product')
  }
})

const displayOrderBadge = isOrderSaved => {
  if (isOrderSaved) {
    return <Badge color="info">Saved</Badge>
  }
  if (isOrderSaved === false) {
    return <Badge color="warning">Modified*</Badge>
  }

  return null
}

const BillingInfo = ({ billingInfo }) => (
  <Fragment>
    {billingInfo.firstName && (
      <Row>
        <Col>
          <h6 className="text-muted">First name</h6>
        </Col>
        <Col>
          <div className="align-right">{billingInfo.firstName}</div>
        </Col>
      </Row>
    )}
    {billingInfo.lastName && (
      <Row>
        <Col>
          <h6 className="text-muted">Last name</h6>
        </Col>
        <Col>
          <div className="align-right">{billingInfo.lastName}</div>
        </Col>
      </Row>
    )}
    {billingInfo.street && (
      <Row>
        <Col>
          <h6 className="text-muted">Address</h6>
        </Col>
        <Col>
          <div className="align-right">{billingInfo.street}</div>
        </Col>
      </Row>
    )}
    {billingInfo.city && (
      <Row>
        <Col>
          <h6 className="text-muted">City</h6>
        </Col>
        <Col>
          <div className="align-right">{billingInfo.city}</div>
        </Col>
      </Row>
    )}
    {billingInfo.state && (
      <Row>
        <Col>
          <h6 className="text-muted">State</h6>
        </Col>
        <Col>
          <div className="align-right">{billingInfo.state}</div>
        </Col>
      </Row>
    )}
    {billingInfo.country && (
      <Row>
        <Col>
          <h6 className="text-muted">Country</h6>
        </Col>
        <Col>
          <div className="align-right">{billingInfo.country}</div>
        </Col>
      </Row>
    )}
  </Fragment>
)

const ManualOrderSummary = ({ state, billingInfo }) => (
  <Card style={{ backgroundColor: '#f8f8f8' }} className="h-100">
    <CardBody>
      <CardTitle className="card-title">
        <Icon icon={ic_shopping_cart} size={20} /> Your order{' '}
        {state.orderId && (
          <>
            <span className="order-id">{Utils.shortId(state.orderId)}</span>{' '}
            {displayOrderBadge(state.isOrderSaved)}
          </>
        )}
      </CardTitle>
      <BillingInfo billingInfo={billingInfo} />
      <br />
      {state.cartItems.map(cartItem => (
        <h6>
          <span
            style={{
              color: '#b42626',
              fontSize: '16px',
              fontWeight: 'bold'
            }}
          >
            {cartItem.quantity}x{' '}
          </span>
          {PRODUCTS_NAME[cartItem.productId]}
        </h6>
      ))}
      <br />
      {state.amountExclTax > 0 && (
        <Row>
          <Col>
            <h5 className="text-muted">Sub-total</h5>
          </Col>
          <Col>
            <h6 className="align-right">
              <Currency
                quantity={state.amountExclTax}
                currency={state.initialOrder.currency}
              />
            </h6>
          </Col>
        </Row>
      )}
      {state.amountTax > 0 && (
        <Row>
          <Col>
            <h6 className="text-muted">VAT</h6>
          </Col>
          <Col>
            <h6 className="align-right">
              <Currency
                quantity={state.amountTax}
                currency={state.initialOrder.currency}
              />
            </h6>
          </Col>
        </Row>
      )}
      {state.total > 0 && (
        <Row>
          <Col>
            <h5 className="text-muted">TOTAL</h5>
          </Col>
          <Col>
            <h5 className="align-right">
              <strong>
                <Currency
                  quantity={state.total}
                  currency={state.initialOrder.currency}
                />
              </strong>
            </h5>
          </Col>
        </Row>
      )}
      <br />
    </CardBody>
  </Card>
)

export default withState(injectState(ManualOrderSummary))
