import React from 'react'
import {
  Badge,
  Col,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem,
  Row,
  UncontrolledDropdown,
  UncontrolledTooltip,
  NavLink
} from 'reactstrap'
import { Container } from 'reactstrap'
import { BsPersonCircle } from 'react-icons/bs'
import { AiOutlineLogout } from 'react-icons/ai'
import { injectState, provideState } from 'reaclette'

import XoLogo from '../../imgs/xo-icon.png'
import VatesLogo from '../../imgs/vates-logo.png'

const withState = provideState({
  initialState: () => ({
    collapseNavbar: true
  }),
  effects: {
    toggleNavbar() {
      this.state.collapseNavbar = !this.state.collapseNavbar
    }
  }
})

const Header = ({ effects, state, email, config, logOut, role }) => (
  <div className="header">
    <Container fluid>
      <Row>
        <Col md={{ size: 10, offset: 1 }}>
          <Navbar expand="sm" dark>
            <NavbarToggler onClick={effects.toggleNavbar} className="mr-2" />
            <Collapse isOpen={!state.collapseNavbar} navbar>
              <Nav className="mr-auto" navbar>
                <NavItem style={{ cursor: 'pointer', marginRight: '3rem' }}>
                  <NavLink href={(config && config.baseUrl) || ''}>
                    <img
                      className="header-logo"
                      src={XoLogo}
                      alt="Xen Orchestra logo"
                      height="45"
                    />
                    <span className="header-logo-text">XEN-ORCHESTRA</span>
                  </NavLink>
                </NavItem>
              </Nav>
              {email && (
                <Nav className="ms-auto" navbar>
                  {role === 'reseller' && (
                    <NavItem className="header-item-badge">
                      <Badge color="info">Reseller</Badge>
                    </NavItem>
                  )}
                  <NavItem className="header-item-user">
                    <UncontrolledDropdown nav inNavbar tag="div">
                      <DropdownToggle nav caret tag="div">
                        <BsPersonCircle size="25" style={{ color: 'white' }} />
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem header>{email}</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem onClick={logOut}>
                          <AiOutlineLogout size="25" />
                          &nbsp; Log out
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </NavItem>
                  <NavItem style={{ cursor: 'pointer' }}>
                    <NavLink
                      href={(config && config.companyUrl) || ''}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt="Vates logo"
                        height="40"
                        id="vatesLogoToolTip"
                        src={VatesLogo}
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target="vatesLogoToolTip"
                      >
                        Vates.fr
                      </UncontrolledTooltip>
                    </NavLink>
                  </NavItem>
                </Nav>
              )}
            </Collapse>
          </Navbar>
        </Col>
      </Row>
    </Container>
  </div>
)

export default withState(injectState(Header))
