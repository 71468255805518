import * as Utils from '@vates/www-xo-utils'
import Currency from 'react-currency-formatter'
import Icon from 'react-icons-kit'
import React, { Fragment } from 'react'
import { Alert, Badge, Card, CardBody, CardTitle, Col, Row } from 'reactstrap'
import { ic_shopping_cart } from 'react-icons-kit/md/ic_shopping_cart'
import { injectState, provideState } from 'reaclette'
import moment from 'moment'

import {
  SUBSCRIPTION,
  PAID_PERIOD,
  PRODUCTS_ICON,
  getProductName
} from '../../purchase-utils'
import { isEmpty } from 'lodash'

const withState = provideState({
  computed: {
    planDurationMessage: (state, props) => {
      if (state._order.paymentModel.type === SUBSCRIPTION) {
        if (state._order.onUpgrade) {
          return null
        }
        return state._order.paymentModel.year === 0 ? 'per month' : 'per year'
      }
      if (state._order.paymentModel.type === PAID_PERIOD) {
        if (state._order.onUpgrade) {
          const endPeriod = moment(
            state._order.waitingUpgrade.endPeriod,
            'x'
          ).format('MMM D, YYYY')
          return `from now to ${endPeriod}`
        }
        return `for ${state._order.paymentModel.year} year${
          state._order.paymentModel.year > 1 ? 's' : ''
        }`
      }
    },
    planBindMessage: state => {
      if (state._order.selfbound === true) {
        return 'Buy for my own use'
      }
      if (state._order.selfbound === false) {
        return 'Buy for another account'
      }
    },
    upgradePrice: state => {
      if (
        state._order.onUpgrade &&
        state.planFormula &&
        state._order.paymentModel.type ===
          Utils.Order.PAYMENT_MODEL.SUBSCRIPTION
      ) {
        const plan =
          state.planFormula[state._order.paymentModel.type][
            state._order.paymentModel.year
          ]
        if (plan && plan.tax && plan.tax.total) {
          return plan.tax.total
        }
      }
      return null
    }
  }
})

const displayOrderBadge = isOrderSaved => {
  if (isOrderSaved) {
    return <Badge color="info">Saved</Badge>
  }
  if (isOrderSaved === false) {
    return <Badge color="warning">Modified*</Badge>
  }

  return null
}

const BillingInfo = ({ billingInfo }) => (
  <Fragment>
    {billingInfo.firstName && (
      <Row>
        <Col>
          <h6 className="text-muted">First name</h6>
        </Col>
        <Col>
          <div className="align-right">{billingInfo.firstName}</div>
        </Col>
      </Row>
    )}
    {billingInfo.lastName && (
      <Row>
        <Col>
          <h6 className="text-muted">Last name</h6>
        </Col>
        <Col>
          <div className="align-right">{billingInfo.lastName}</div>
        </Col>
      </Row>
    )}
    {billingInfo.street && (
      <Row>
        <Col>
          <h6 className="text-muted">Address</h6>
        </Col>
        <Col>
          <div className="align-right">{billingInfo.street}</div>
        </Col>
      </Row>
    )}
    {billingInfo.city && (
      <Row>
        <Col>
          <h6 className="text-muted">City</h6>
        </Col>
        <Col>
          <div className="align-right">{billingInfo.city}</div>
        </Col>
      </Row>
    )}
    {billingInfo.state && (
      <Row>
        <Col>
          <h6 className="text-muted">State</h6>
        </Col>
        <Col>
          <div className="align-right">{billingInfo.state}</div>
        </Col>
      </Row>
    )}
    {billingInfo.country && (
      <Row>
        <Col>
          <h6 className="text-muted">Country</h6>
        </Col>
        <Col>
          <div className="align-right">{billingInfo.country}</div>
        </Col>
      </Row>
    )}
  </Fragment>
)

const ComputeTotal = ({ cart, currency, planDurationMessage }) => {
  const cartItemTotal = cart.find(cartItem => cartItem.type === 'total')
  if (!cartItemTotal) {
    return null
  }
  const amountInclTax = cartItemTotal.amount + cartItemTotal.tax.amount

  return (
    <Fragment>
      {cartItemTotal.amount > 0 && (
        <Row>
          <Col>
            <h5 className="text-muted">Sub-total</h5>
          </Col>
          <Col>
            <h6 className="align-right">
              <Currency quantity={cartItemTotal.amount} currency={currency} />
            </h6>
          </Col>
        </Row>
      )}
      {cartItemTotal.tax.amount > 0 && (
        <Row>
          <Col>
            <h6 className="text-muted">VAT</h6>
          </Col>
          <Col>
            <h6 className="align-right">
              <Currency
                quantity={cartItemTotal.tax.amount}
                currency={currency}
              />
            </h6>
          </Col>
        </Row>
      )}
      {amountInclTax > 0 && (
        <Row>
          <Col>
            <h5 className="text-muted">TOTAL</h5>
          </Col>
          <Col>
            <div className="align-right">
              <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
                <Currency quantity={amountInclTax} currency={currency} />
              </span>
              <br />
              <span className="text-muted" style={{ whiteSpace: 'nowrap' }}>
                {planDurationMessage}
              </span>
            </div>
          </Col>
        </Row>
      )}
    </Fragment>
  )
}

const CartItemAmount = ({ cartItem, currency }) => (
  <h6 className="align-right">
    {cartItem.quantity > 1 && (
      <span className="summary-highlight">{cartItem.quantity}x </span>
    )}
    {!isNaN(cartItem.amount) && (
      <Currency quantity={cartItem.amount} currency={currency} />
    )}
  </h6>
)

const Plan = ({ paymentModel }) => {
  if (paymentModel.year === 0) {
    return '(1 month)'
  }

  if (paymentModel.year) {
    return `(${paymentModel.year} year${paymentModel.year > 1 ? 's' : ''})`
  }

  return null
}

const CartItem = ({ cartItem, currency, paymentModel }) => {
  const iconProduct = PRODUCTS_ICON[cartItem.productId]

  if (cartItem.type === 'product') {
    return (
      <Row style={{ alignItems: 'center' }}>
        <Col md="8">
          {iconProduct && (
            <img
              alt="logo product"
              height="25"
              src={PRODUCTS_ICON[cartItem.productId]}
            />
          )}
          <span className="summary-highlight">
            {getProductName(cartItem.productId)}{' '}
            <Plan paymentModel={paymentModel} />
          </span>
        </Col>
        <Col md="4">
          <CartItemAmount cartItem={cartItem} currency={currency} />
        </Col>
      </Row>
    )
  }

  if (cartItem.type === 'discount' && cartItem.amount) {
    return (
      <Row style={{ alignItems: 'center' }}>
        <Col>
          {iconProduct && (
            <img
              alt="logo product"
              height="25"
              src={PRODUCTS_ICON[cartItem.productId]}
            />
          )}
          {cartItem.name}
        </Col>
        <Col>
          <CartItemAmount cartItem={cartItem} currency={currency} />
        </Col>
      </Row>
    )
  }

  if (cartItem.type === 'gift') {
    return (
      <Row style={{ alignItems: 'center' }}>
        <Col>
          {iconProduct && (
            <img
              alt="logo product"
              height="25"
              src={PRODUCTS_ICON[cartItem.productId]}
            />
          )}
          <span className="summary-highlight">
            {getProductName(cartItem.productId)} (Gift)
          </span>
        </Col>
        <Col>
          <CartItemAmount cartItem={cartItem} currency={currency} />
        </Col>
      </Row>
    )
  }

  return null
}

const DisplayAlertPriceUpgrade = ({ order }) => {
  if (
    !isEmpty(order.cart) &&
    order.onUpgrade &&
    order.paymentModel.type === Utils.Order.PAYMENT_MODEL.SUBSCRIPTION
  ) {
    return (
      <Alert color="secondary">
        The final price may vary slightly due to proration.
      </Alert>
    )
  }
  return null
}
const DisplayPaidUpgrade = ({ initialOrder, order, upgradePrice }) => {
  if (
    !isEmpty(order.cart) &&
    order.onUpgrade &&
    order.paymentModel.type === Utils.Order.PAYMENT_MODEL.SUBSCRIPTION &&
    order.paymentModel.year !== undefined &&
    upgradePrice
  ) {
    const recurrence = order.paymentModel.year === 0 ? 'per month' : 'per year'

    return (
      <Alert color="secondary">
        You will pay this price now, then{' '}
        <Currency quantity={upgradePrice} currency={order.currency} />{' '}
        {recurrence} from{' '}
        <b>
          {moment(order.waitingUpgrade.endPeriod, 'x').format('MMM D, YYYY')}
        </b>
      </Alert>
    )
  }
  return null
}

const Summary = ({ state, billingInfo }) => (
  <Card style={{ backgroundColor: '#f8f8f8' }} className="h-100">
    <CardBody>
      <CardTitle className="card-title">
        <Icon icon={ic_shopping_cart} size={20} /> Your order{' '}
        {state._order.id && (
          <>
            <span className="order-id">{Utils.shortId(state._order.id)}</span>{' '}
            {displayOrderBadge(state.isOrderSaved)}
          </>
        )}
      </CardTitle>
      <BillingInfo billingInfo={billingInfo} />
      <br />
      {state._order.cart.map((cartItem, index) => (
        <CartItem
          key={index}
          cartItem={cartItem}
          currency={state._order.currency}
          paymentModel={state._order.paymentModel}
        />
      ))}
      <br />
      <ComputeTotal
        cart={state._order.cart}
        currency={state._order.currency}
        planDurationMessage={state.planDurationMessage}
      />
      <br />
      <DisplayAlertPriceUpgrade order={state._order} />
      <DisplayPaidUpgrade
        upgradePrice={state.upgradePrice}
        initialOrder={state.initialOrder}
        order={state._order}
      />
      {state.planBindMessage && (
        <Row>
          <Col>
            <span className="text-muted">Buy it for:</span>
          </Col>
          <Col>
            <h6 className="align-right">
              <strong>{state.planBindMessage}</strong>
            </h6>
          </Col>
        </Row>
      )}
    </CardBody>
  </Card>
)

export default withState(injectState(Summary))
