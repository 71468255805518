import { request } from './fetch-api'
import { addPurchaseProperties } from '@vates/www-xo-utils'

const xpApiUrl = '/api/purchaser'

export const purchaseTransfer = (
  xwToken,
  plan,
  quantity,
  billingInfo,
  year,
  selfBound
) =>
  request(xpApiUrl, 'purchaseTransfer', {
    xwToken,
    plan,
    quantity,
    billingInfo,
    year,
    selfBound
  })

export const extendTransfer = (xwToken, purchaseNumber, billingInfo, year) =>
  request(xpApiUrl, 'extendTransfer', {
    xwToken,
    purchaseNumber,
    billingInfo,
    year
  })

export const extendPurchaseToOrder = (xwToken, purchaseNumber, order) =>
  request(xpApiUrl, 'extendPurchaseToOrder', {
    xwToken,
    purchaseNumber,
    order
  })

export const purchaseStripe = (
  xwToken,
  plan,
  cardToken,
  billingInfo,
  year,
  selfBound
) =>
  request(xpApiUrl, 'purchaseStripe', {
    xwToken,
    plan,
    cardToken,
    billingInfo,
    year,
    selfBound
  })

export const cancelPurchase = (xwToken, purchaseNumber, reason, details) =>
  request(xpApiUrl, 'cancelTransfer', {
    // TEMPORARY METHOD
    xwToken,
    purchaseNumber,
    reason, // Unused in TEMP situation
    details // Unused in TEMP situation
  })

export const upgradeStripe = (
  xwToken,
  plan,
  cardToken,
  billingInfo,
  year,
  purchaseNumber
) =>
  request(xpApiUrl, 'upgradeStripe', {
    xwToken,
    plan,
    cardToken,
    billingInfo,
    year,
    purchaseNumber
  })

export const upgradeTransfer = (
  xwToken,
  purchaseNumber,
  plan,
  quantity,
  billingInfo,
  year
) =>
  request(xpApiUrl, 'upgradeTransfer', {
    xwToken,
    purchaseNumber,
    plan,
    quantity,
    billingInfo,
    year
  })

export const placeOrder = (xwToken, cartItems, planFormula, role, selfbound) =>
  request(xpApiUrl, 'placeOrder', {
    xwToken,
    cartItems,
    planFormula,
    role,
    selfbound
  })

export const updateOrder = (
  orderId,
  xwToken,
  cartItems,
  planFormula,
  role,
  selfbound
) =>
  request(xpApiUrl, 'updateOrder', {
    orderId,
    xwToken,
    cartItems,
    planFormula,
    role,
    selfbound
  })

export const _placeOrder = ( xwToken, order ) =>{
  return request(xpApiUrl, 'placeOrder2', { xwToken, order })
}

export const _updateOrder = order =>
  request(xpApiUrl, 'updateOrder2', { order })

export const placeUpgradeOrder = (xwToken, order) =>
  request(xpApiUrl, 'placeUpgradeOrder', { xwToken, order })

export const updateUpgradeOrder = (xwToken, order) =>
  request(xpApiUrl, 'updateUpgradeOrder', { xwToken, order })

export const refreshUpgradeOrder = (xwToken, orderId) =>
  request(xpApiUrl, 'refreshUpgradeOrder', { xwToken, orderId })

export const getOrder = async orderId => {
  const order = await request(xpApiUrl, 'getOrder', { orderId })
  addPurchaseProperties(order)
  return order
}

export const bindEndUserToOrder = (xwToken, email, orderId) =>
  request(xpApiUrl, 'bindEndUserToOrder', { xwToken, email, orderId })

export const chooseCardPayment = orderId =>
  request(xpApiUrl, 'chooseCardPayment', { orderId })

export const chooseTransferPayment = orderId =>
  request(xpApiUrl, 'chooseTransferPayment', { orderId })

export const initCreditCardPayment = (xwToken, orderId) =>
  request(xpApiUrl, 'initCreditCardPayment', { xwToken, orderId })

export const confirmRecurringPayment = (xwToken, orderId, paymentIntent) =>
  request(xpApiUrl, 'confirmRecurringPayment', {
    xwToken,
    orderId,
    paymentIntent
  })

export const createOrderFromQuote = (xwToken, quoteNumber) =>
  request(xpApiUrl, 'createOrderFromQuote', { xwToken, quoteNumber })

  export const initUpgradeOrderSubscription = (xwToken, orderId) =>
  request(xpApiUrl, 'initUpgradeOrderSubscription', {
    xwToken,
    orderId
  })

export const confirmUpgradeOrderSubscription = (
  xwToken,
  orderId
) =>
  request(xpApiUrl, 'confirmUpgradeOrderSubscription', {
    xwToken,
    orderId
  })

export const upgradeOrderWithTransfer = (
  xwToken,
  orderId
) =>
  request(xpApiUrl, 'upgradeOrderWithTransfer', {
    xwToken,
    orderId
  })

export const extendOrder = (xwToken, initialOrderId, order) =>
  request(xpApiUrl, 'extendOrder', { xwToken, initialOrderId, order })

export const intentOneShotPayment = (xwToken, orderId) =>
  request(xpApiUrl, 'intentOneShotPayment', { xwToken, orderId })

export const confirmOneShotPayment = (xwToken, orderId) =>
  request(xpApiUrl, 'confirmOneShotPayment', {
    xwToken,
    orderId
  })

export const intentOneShotPaymentManualOrder = (xwToken, orderId) =>
  request(xpApiUrl, 'intentOneShotPaymentManualOrder', { xwToken, orderId })

export const confirmOneShotPaymentManualOrder = (xwToken, orderId) =>
  request(xpApiUrl, 'confirmOneShotPaymentManualOrder', {
    xwToken,
    orderId
  })
